import React from "react";
import Loading from "../../../commons/general/Loading";
import { Box, Grid, Link, Text, useMediaQuery } from "@chakra-ui/react";
import Dashboard from "./CustomerDashboard";

const CustomerDetail = ({ customer }) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  if (!customer) {
    return <Loading />;
  }

  return (
    <Box mt={4}>
      <Grid
        textAlign={"center"}
        templateColumns={isLargerThan768 ? "repeat(3, 1fr)" : "repeat(1, 1fr)"}
        gap={4}
        mb="4"
      >
        <Text fontWeight={"bold"} color={"gray.500"}>
          Contact: {customer.name}
        </Text>
        <Text fontWeight={"bold"} color={"gray.500"}>
          Email: {customer.email}
        </Text>
        <Text fontWeight={"bold"} color={"gray.500"}>
          Phone: {customer.phone}
        </Text>
        <Text fontWeight={"bold"} color={"gray.500"}>
          Address: {customer.address}
        </Text>
        <Text fontWeight={"bold"} color={"gray.500"}>
          Server URL:
          <Link href={customer.serverURL} isExternal color="blue.400" ml={2}>
            {customer.serverURL}
          </Link>
        </Text>
        <Text fontWeight={"bold"} color={"gray.500"}>
          Pbx Url: {customer.domain.domain_name}
        </Text>
      </Grid>
      {isLargerThan768 && <Dashboard />}
    </Box>
  );
};

export default CustomerDetail;
