import React, { useEffect, useState } from "react";
import { Center } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import UserAuthenticatedRoutes from "./routes/UserAuthenticatedRoutes";
import LoginRoutes from "./routes/LoginRoutes";
import { URL_USER } from "./config/apiBaseURL";
import Navbar from "./components/general/Navbar";
import Loading from "./commons/general/Loading";
import { handleUserRole } from "./utils/usersStatesDispatch";
import { setUser } from "./store/reducers/user.reducer";
import SocketListener from "./components/chat/SocketListener";

function App() {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const userLocalStorg = JSON.parse(localStorage.getItem("user"));

    async function fetchUser() {
      try {
        const response = await axios.post(`${URL_USER}/auth/me/`, {
          userLocalStorg,
        });
        if (response) {
          dispatch(setUser(response.data));
          handleUserRole(response.data, dispatch);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetchUser();
  }, [dispatch]);

  if (loading) {
    return (
      <Center mt={200}>
        <Loading />
      </Center>
    );
  } else if (user.state === "rejected") {
    return <LoginRoutes />;
  } else {
    return (
      <>
        <SocketListener user={user} />
        <Navbar />
        <UserAuthenticatedRoutes user={user} />
      </>
    );
  }
}

export default App;
