import axios from "axios";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { URL_USER } from "../../config/apiBaseURL";

const initialState = [];

export const createSoftphoneUser = createAsyncThunk(
  "CREATE_SOFTPHONE_USER",
  async (data) => {
    const response = await axios.post(
      `${URL_USER}/users/softphoneUser/create`,
      data
    );
    return response.data;
  }
);

export const getAllSoftphoneUser = createAsyncThunk(
  "GET_ALL_SOFTPHONE_USERS",
  async () => {
    const response = await axios.get(`${URL_USER}/users/softphoneUser/all`);
    return response.data;
  }
);

export const getAllSoftphoneUserByCompany = createAsyncThunk(
  "GET_SOFTPHONE_USER_BY_COMPANY",
  async (companyId) => {
    const response = await axios.get(
      `${URL_USER}/softphoneUser/all/${companyId}`
    );
    return response.data;
  }
);
export const getSoftphoneUser = createAsyncThunk(
  "GET_SOFTPHONE_USER",
  async (id) => {
    const response = await axios.get(`${URL_USER}/users/user/${id}`);
    return response.data;
  }
);

export const deleteSoftphoneUser = createAsyncThunk(
  "DELETE_SOFTPHONE_USER",
  async (softphoneUserId) => {
    const response = await axios.delete(
      `${URL_USER}/users/softphoneUser/delete/${softphoneUserId}`
    );
    return response.data;
  }
);

export const updateSoftphoneUser = createAsyncThunk(
  "UPDATE_SOFTPHONE_USER",
  async (softphoneUserData) => {
    const response = await axios.put(
      `${URL_USER}/users/softphoneUser/update/`,
      softphoneUserData
    );
    return response.data;
  }
);

export const setSoftphoneUser = createAction("SET_SOFTPHONE_USER");

const softphoneUserReducer = createReducer(initialState, {
  [createSoftphoneUser.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [getAllSoftphoneUser.fulfilled]: (state, action) => {
    return action.payload;
  },
  [getAllSoftphoneUserByCompany.fulfilled]: (state, action) => {
    return action.payload;
  },
  [getSoftphoneUser.fulfilled]: (state, action) => {
    return action.payload;
  },
  [deleteSoftphoneUser.fulfilled]: (state, action) => {
    if (action.payload) {
      const filteredUsers = state.filter(
        (user) => user._id !== action.payload._id
      );
      return filteredUsers;
    } else {
      return state;
    }
  },
  [updateSoftphoneUser.fulfilled]: (state, action) => {
    const updatedUser = action.payload;
    const index = state.findIndex((user) => user._id === updatedUser._id);
    if (index !== -1) {
      state[index] = updatedUser;
    }
  },
  [setSoftphoneUser]: (state, action) => {
    return action.payload;
  },
});

export default softphoneUserReducer;
