import React from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Heading,
  Center,
  useMediaQuery,
} from "@chakra-ui/react";
import TableProducts from "./products/TableProducts";
import TableCategories from "./categories/TableCategories";
import TablePromotions from "./promotions/TablePromotions";
import TableAdvertisment from "./advertisement/TableAdvertisment";
import TableQuotes from "./quotes/TableQuotes";

const HomeMarketPlace = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <Tabs
      mt={0}
      colorScheme="orange"
      variant="soft-rounded"
      width={"100%"}
      isFitted={isLargerThan768}
    >
      <Center>
        <Heading
          fontSize={{ base: "2xl", md: "4xl" }}
          fontWeight="bold"
          textAlign="center"
          mb={10}
          color={"nevtis.1"}
        >
          Marketplace
        </Heading>
      </Center>

      <TabList overflowX={isLargerThan768 ? "visible" : "auto"}>
        <Tab>Products</Tab>
        <Tab>Categories</Tab>
        <Tab>Promotions</Tab>
        <Tab>Advertisement</Tab>
        <Tab>Quotes</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Box width="100%">
            <TableProducts />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <TableCategories />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <TablePromotions />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <TableAdvertisment />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <TableQuotes />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default HomeMarketPlace;
