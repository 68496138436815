import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
  Stack,
  TableContainer,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
/* import CreateSeller from "./CreateSeller"; */
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Pagination from "../../../commons/general/Pagination";
import CreateProduct from "./CreateProducts";
import axios from "axios";
import EditProduct from "./EditProduct";
import DeleteProduct from "./DeleteProduct";

const TableProducts = () => {
  const user = useSelector((state) => state.user);
  const [products, setProducts] = useState([]);
  const path = useLocation();
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [ItemsPerPage, setItemsPerPage] = useState(100);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const filteredDataSw = products;
  const filteredData = filteredDataSw.filter((item) =>
    item.title.toLowerCase().includes(searchValue.toLowerCase())
  );

  useEffect(() => {
    axios.get("https://api.nevtis.com/marketplace/products/all").then((res) => {
      setProducts(res.data);
    });
  }, []);

  const handleInputPerPage = (value) => {
    setItemsPerPage(Number(value));
  };

  const sections = filteredData.reduce(
    (acc, item, index) =>
      index % ItemsPerPage === 0
        ? [...acc, filteredData.slice(index, index + ItemsPerPage)]
        : acc,
    []
  );

  const currentSection = sections[currentPage - 1];
  const handleSearchChange = (e) => setSearchValue(e.target.value);

  return (
    <Box mt={4} mx={10}>
      <Stack direction={{ base: "column", md: "row" }} spacing={10} mb={4}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by Product Name"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <Flex justifyContent="flex-end" alignItems="center">
          <Text color={"nevtis.1"} mr={2}>
            Items per Page
          </Text>
          <NumberInput
            size="xs"
            maxW={16}
            defaultValue={ItemsPerPage}
            min={1}
            onChange={handleInputPerPage}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Stack>
      <CreateProduct setProducts={setProducts} />

      <TableContainer
        mt={4}
        maxHeight={path.pathname === "/client" ? "320px" : "800px"}
        overflowY="scroll"
      >
        <Table variant="simple" size={isDesktop ? "2xl" : "xs"}>
          <Thead
            position={"sticky"}
            zIndex={1}
            top={0}
            bg={"white"}
            fontSize={!isDesktop && "xs"}
            textAlign={!isDesktop && "center"}
          >
            <Tr>
              <Th
                color={"rgba(255,134,0)"}
                height="50px"
                textAlign={"center"}
                fontSize={"sm"}
              >
                Tittle
              </Th>
              <Th
                color={"rgba(255,134,0)"}
                height="50px"
                textAlign={"center"}
                fontSize={"sm"}
              >
                Category
              </Th>
              <Th
                color={"rgba(255,134,0)"}
                height="50px"
                textAlign={"center"}
                fontSize={"sm"}
              >
                Stock
              </Th>
              <Th
                color={"rgba(255,134,0)"}
                height="50px"
                textAlign={"center"}
                fontSize={"sm"}
              >
                Price
              </Th>
              <Th
                color={"rgba(255,134,0)"}
                height="50px"
                textAlign={"center"}
                fontSize={"sm"}
              >
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentSection &&
              currentSection
                .filter((prod) => prod._id !== user._id)
                .map((item) => (
                  <Tr key={item._id}>
                    <Td textAlign={"center"}>{item.title}</Td>
                    <Td textAlign={"center"}>{item.category.name}</Td>
                    <Td textAlign={"center"}>{item.inStock}</Td>
                    <Td textAlign={"center"}>{item.price}</Td>
                    <Td textAlign={"center"}>
                      <EditProduct product={item} setProducts={setProducts} />
                      <DeleteProduct product={item} setProducts={setProducts} />
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        sections={sections}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </Box>
  );
};

export default TableProducts;
