import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import EmailChannel from "./EmailChannel";
import SmsChannel from "./SmsChannel";

const Comunications = ({ user }) => {
  return (
    <Box
      borderRadius={"xl"}
      maxHeight={"650px"}
      m={2}
      flex="1"
      align="center"
      bg={"white"}
      boxShadow="0 4px 12px 0 rgba(0,0,0,0.5)"
    >
      <Tabs>
        <TabList>
          <Tab color={"nevtis.1"} fontWeight={"bold"}>
            Email
          </Tab>
          <Tab color={"nevtis.1"} fontWeight={"bold"}>
            SMS
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <EmailChannel user={user} />
          </TabPanel>
          <TabPanel>
            <SmsChannel user={user} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Comunications;
