import axios from "axios";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import {URL_USER} from "../../config/apiBaseURL";

const initialState = [];

export const createService = createAsyncThunk(
  "CREATE_SERVICE",
  async (serviceData) => {
    const response = await axios.post(
      `${{URL_USER}}/service/create`,
      serviceData
    );
    return response.data;
  }
);

export const createServiceRequest = createAsyncThunk(
  "CREATE_SERVICE_REQUEST",
  async (serviceRequestData) => {
    const response = await axios.post(
      `${{URL_USER}}/service/createServiceRequest`,
      serviceRequestData
    );
    return response.data;
  }
);

export const getAllServices = createAsyncThunk("GET_ALL_SERVICES", async () => {
  const response = await axios.get(`${{URL_USER}}/service/all`);
  return response.data;
});

export const getServicesRequest = createAsyncThunk(
  "GET_SERVICES_REQUEST",
  async () => {
    const response = await axios.get(
      `${{URL_USER}}/service/allServicesRequest`
    );
    return response.data;
  }
);

export const getServicesRequestAvailable = createAsyncThunk(
  "GET_SERVICES_REQUEST_AVAILABLE",
  async () => {
    const response = await axios.get(
      `${{URL_USER}}/service/serviceRequestAvailable`
    );
    return response.data;
  }
);

export const getService = createAsyncThunk("GET_SERVICE", async (serviceId) => {
  const response = await axios.get(`${{URL_USER}}/service/${serviceId}`);
  return response.data;
});

export const deleteService = createAsyncThunk(
  "DELETE_SERVICE",
  async (serviceId) => {
    const response = await axios.delete(
      `${{URL_USER}}/service/delete/${serviceId}`
    );
    return response.data;
  }
);

export const updateService = createAsyncThunk(
  "UPDATE_SERVICE",
  async (serviceData) => {
    const response = await axios.put(
      `${{URL_USER}}/service/update`,
      serviceData
    );
    return response.data;
  }
);

export const setService = createAction("SET_SERVICE");

const serviceReducer = createReducer(initialState, {
  [createService.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [createServiceRequest.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [getAllServices.fulfilled]: (state, action) => {
    return action.payload;
  },
  [getServicesRequest.fulfilled]: (state, action) => {
    return action.payload;
  },
  [getServicesRequestAvailable.fulfilled]: (state, action) => {
    return action.payload;
  },
  [getService.fulfilled]: (state, action) => {
    return action.payload;
  },
  [deleteService.fulfilled]: (state, action) => {
    const { acknowledged, deletedCount } = action.payload;
    if (acknowledged && deletedCount === 1) {
      return state.filter((service) => service._id !== action.meta.arg);
    } else {
      return state;
    }
  },
  [updateService.fulfilled]: (state, action) => {
    const updatedService = action.payload;
    const index = state.findIndex(
      (service) => service._id === updatedService._id
    );
    if (index !== -1) {
      state[index] = updatedService;
    }
  },
  [setService]: (state, action) => {
    return action.payload;
  },
});

export default serviceReducer;
