import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Pagination from "../../../commons/general/Pagination";
import CreateAdvertisment from "./CreateAdvertisment";
import { showToast } from "../../../commons/SweetAlertToast";
import { FaWindowClose, FaCheckCircle } from "react-icons/fa";

const TableAdvertisment = () => {
  const [advertisement, setAdvertisement] = useState([]);
  const [selectedAdvertisement, setselectedAdvertisement] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedMessage, setEditedMessage] = useState("");
  const [editedVisible, setEditedVisible] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [isDesktop] = useMediaQuery("(min-width: 800px)");

  useEffect(() => {
    axios
      .get("https://api.nevtis.com/marketplace/advertisement/all")
      .then((res) => {
        setAdvertisement(res.data);
      })
      .catch((error) => {
        console.error("Error fetching advertisement:", error);
      });
  }, []);

  const handleDeleteMessage = async () => {
    try {
      await axios.delete(
        `https://api.nevtis.com/marketplace/advertisement/delete/${selectedAdvertisement._id}`
      );
      setAdvertisement((prevCategories) =>
        prevCategories.filter((cat) => cat._id !== selectedAdvertisement._id)
      );
      showToast("Promotion Deleted", "success");
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting Promotion:", error);
    }
  };

  const handleEditMessage = async () => {
    try {
      const res = await axios.put(
        `https://api.nevtis.com/marketplace/advertisement/update/${selectedAdvertisement._id}`,
        {
          title: editedTitle,
          message: editedMessage,
          isVisible: editedVisible,
        }
      );
      showToast("Advertisement Updated", "success");
      setAdvertisement((prevAdvertisement) =>
        prevAdvertisement.map((adv) =>
          adv._id === selectedAdvertisement._id
            ? {
                ...adv,
                title: res.data.title,
                message: res.data.message,
              }
            : adv
        )
      );
      setIsEditing(false);
      setselectedAdvertisement(null);
      setEditedTitle("");
      setEditedMessage("");
      setEditedVisible(false);
    } catch (error) {
      console.error("Error editing Promotion:", error);
    }
  };

  const handleOpenEditModal = (advertisement) => {
    setselectedAdvertisement(advertisement);
    setEditedTitle(advertisement.title);
    setEditedMessage(advertisement.message);
    setEditedVisible(advertisement.isVisible);
    setIsEditing(true);
  };

  const handleCloseEditModal = () => {
    setIsEditing(false);
    setselectedAdvertisement(null);
    setEditedTitle("");
    setEditedMessage("");
    setEditedVisible(false);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleInputPerPage = (value) => {
    setItemsPerPage(Number(value));
  };

  const filteredData = advertisement.filter((item) =>
    item.title.toLowerCase().includes(searchValue.toLowerCase())
  );

  const sections = filteredData.reduce(
    (acc, item, index) =>
      index % itemsPerPage === 0
        ? [...acc, filteredData.slice(index, index + itemsPerPage)]
        : acc,
    []
  );

  const currentSection = sections[currentPage - 1];

  const path = useLocation();

  const truncateMessage = (message) => {
    const words = message.split(" ");
    const truncatedWords = words.slice(0, 10);
    return truncatedWords.join(" ");
  };

  return (
    <Box mt={4} mx={10}>
      <Stack direction={{ base: "column", md: "row" }} spacing={10} mb={4}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by Title"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <Flex justifyContent="flex-end" alignItems="center">
          <Text color={"nevtis.1"} mr={2}>
            Items per Page
          </Text>
          <NumberInput
            size="xs"
            maxW={16}
            defaultValue={itemsPerPage}
            min={1}
            onChange={handleInputPerPage}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Stack>

      <CreateAdvertisment setAdvertisement={setAdvertisement} />

      <TableContainer
        mt={4}
        maxHeight={path.pathname === "/client" ? "320px" : "800px"}
        overflowY="scroll"
      >
        <Table variant="simple" size={isDesktop ? "2xl" : "xs"}>
          <Thead
            position={"sticky"}
            zIndex={1}
            top={0}
            bg={"white"}
            fontSize={!isDesktop && "xs"}
            textAlign={!isDesktop && "center"}
          >
            <Tr>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Title
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Message
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Is Visible?
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentSection &&
              currentSection.map((item) => (
                <Tr key={item._id}>
                  <Td textAlign="center">{item.title}</Td>
                  <Td textAlign="center">{truncateMessage(item.message)}</Td>
                  {item.isVisible ? (
                    <Td textAlign="center">
                      <Center>
                        <FaCheckCircle color="green" />
                      </Center>
                    </Td>
                  ) : (
                    <Td textAlign="center">
                      <Center>
                        <FaWindowClose color="red" />
                      </Center>
                    </Td>
                  )}

                  <Td textAlign="center">
                    <Button
                      colorScheme="blue"
                      size="xs"
                      mr={2}
                      onClick={() => handleOpenEditModal(item)}
                    >
                      Edit
                    </Button>
                    <Button
                      colorScheme="red"
                      size="xs"
                      onClick={() => {
                        setselectedAdvertisement(item);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        sections={sections}
        currentPage={currentPage}
        handlePageChange={setCurrentPage}
      />

      <Modal isOpen={isEditing} onClose={handleCloseEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Promotion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Input
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                placeholder="Promotion Name"
              />
              <Input
                value={editedMessage}
                onChange={(e) => setEditedMessage(e.target.value)}
                placeholder="Promotion Discount"
              />
              <Checkbox
                value={editedVisible}
                onChange={(e) => setEditedVisible(e.target.value)}
                placeholder="Promotion Discount"
              >
                Is Visible?
              </Checkbox>
            </Stack>
            <Button mt={4} colorScheme="blue" onClick={handleEditMessage}>
              Save Changes
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Advetisement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this Advetisement?</Text>
            <Button mt={4} colorScheme="red" onClick={handleDeleteMessage}>
              Confirm Delete
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TableAdvertisment;
