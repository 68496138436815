// Home.js
import React from "react";
import { Container, Box, Image, Text, Flex, Center } from "@chakra-ui/react";
import { FaCompress, FaExpand, FaUser } from "react-icons/fa";
import useDateAndFullscreen from "./useDateAndFullscreen";
import useCalls from "./useCalls";
import ChartComponent from "./ChartComponent";
import SummaryComponent from "./SummaryComponent";
import CallsList from "./CallsList";
import DateRangeSelector from "./DateRangeSelector";
import useChartDataAndCalls from "./useChartDataAndCalls";
import logo from "../../assets/logo1.png";
import { Link } from "react-router-dom";

const Home = () => {
  const {
    openStartCalendar,
    setOpenStartCalendar,
    openEndCalendar,
    setOpenEndCalendar,
    startSelectedDate,
    endSelectedDate,
    fullscreen,
    toggleFullScreen,
    formattedNextDay,
    handleStartDateSelect,
    handleEndDateSelect,
  } = useDateAndFullscreen();

  const calls = useCalls(startSelectedDate, formattedNextDay, endSelectedDate);
  const COLORS = [
    "#36a2eb",
    "#ffce56",
    "#ff6384",
    "#4bc0c0",
    "#9966cc",
    "#ff3752",
  ];
  const { chartData, totalCalls, llamadasFiltradas } =
    useChartDataAndCalls(calls);

  return (
    <Container maxW={fullscreen ? "container.2xl" : "container.lg"}>
      <Link to={"/"}>
        <Image src={logo} height="12" p={2} mt={2} />
      </Link>

      {/* BOTONES SELECTOR DE HORARIOS */}
      {!fullscreen && (
        <Box mt={5} display="flex" alignItems="center">
          <Text color={"nevtis.1"} fontWeight={"bold"} mr={2}>
            Start Date
          </Text>
          <DateRangeSelector
            selectedDate={startSelectedDate}
            handleDateSelect={handleStartDateSelect}
            isOpen={openStartCalendar}
            setOpenCalendar={setOpenStartCalendar}
          />
          <Text color={"nevtis.1"} fontWeight={"bold"} mr={2}>
            End Date
          </Text>
          <DateRangeSelector
            selectedDate={endSelectedDate}
            handleDateSelect={handleEndDateSelect}
            isOpen={openEndCalendar}
            setOpenCalendar={setOpenEndCalendar}
          />
        </Box>
      )}

      {/* RANGO DE FECHA Y CANTIDAD DE LLAMADAS */}
      <SummaryComponent
        fullscreen={fullscreen}
        startSelectedDate={startSelectedDate}
        endSelectedDate={endSelectedDate}
        totalCalls={totalCalls}
      />
      <Flex cursor="pointer" mt={20} pr={10} shadow="xl" borderRadius="lg">
        <Box flex={fullscreen ? "30%" : "40%"}>
          {llamadasFiltradas.length > 0 && (
            <CallsList
              llamadasFiltradas={llamadasFiltradas}
              limite={llamadasFiltradas.length}
              fullscreen={fullscreen}
            />
          )}
        </Box>
        {/* GRAFICOS */}
        <Box flex={fullscreen ? "70%" : "60%"}>
          <ChartComponent
            fullscreen={fullscreen}
            chartData={chartData}
            totalCalls={totalCalls}
          />
        </Box>
      </Flex>
      {/* BOTON DE FULL SCREEM */}
      <Flex justify="flex-end" alignItems={"flex-end"} mr={8} mt={2}>
        {fullscreen ? (
          <FaCompress
            size={24}
            color="rgba(255,134,0)"
            cursor="pointer"
            onClick={toggleFullScreen}
          />
        ) : (
          <FaExpand
            size={24}
            color="rgba(255,134,0)"
            cursor="pointer"
            onClick={toggleFullScreen}
          />
        )}
      </Flex>
      <Center>
        <Flex flexDir={"row"}>
          {chartData.map((entry, index) => (
            <>
              <FaUser
                size={fullscreen ? 40 : 24}
                color={COLORS[index % COLORS.length]}
              />
              <Text
                textAlign={"center"}
                color={COLORS[index % COLORS.length]}
                ml={2}
                mr={12}
                fontWeight={"bold"}
                fontSize={fullscreen ? "2xl" : "lg"}
              >
                {entry.name} ({entry.value})
              </Text>
            </>
          ))}
        </Flex>
      </Center>
    </Container>
  );
};

export default Home;
