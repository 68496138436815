import axios from "axios";
import { useEffect, useState } from "react";
import { URL_USER } from "../config/apiBaseURL";

export const useFetchRecipient = (chat, user) => {
  const [recipientUser, setRecipientUser] = useState(null);
  const [error, setError] = useState(null);

  const recipientId = chat?.members.find(id => id !== user._id);

  useEffect(() => {
    const getUser = async () => {
      try {
        if (!recipientId) return;
        const resp = await axios.get(`${URL_USER}/users/user/${recipientId}`);
        setRecipientUser(resp.data);
      } catch (error) {
        setError(error);
      }
    };
    getUser();
  }, [recipientId]); // Added recipientId to dependency array

  return { recipientUser, error }; // Return error state
};
