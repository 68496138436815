import React from "react";
import { useParams } from "react-router-dom";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Link as LinkChakra,
  TabPanel,
  Box,
  Heading,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Button,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import Loading from "../../../commons/general/Loading";
import { useSelector } from "react-redux";
import { IoSettingsSharp } from "react-icons/io5";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { RiMailSendLine } from "react-icons/ri";
import Comunications from "../../Actions/Comunications";
import Activities from "../../Actions/Activities";
import SoftphoneDetail from "./SoftphoneDetail";
import EditSoftphone from "./EditSoftphone";
import DeleteSoftphone from "./DeleteSoftphone";
import axios from "axios";
import { showToast } from "../../../commons/SweetAlertToast";

const SoftphoneDetailsTabs = () => {
  const userLocalStorg = JSON.parse(localStorage.getItem("user"));
  const master = userLocalStorg.token;
  const { softphoneId } = useParams();
  const user = useSelector((state) => state.user);
  const allSoftphone = useSelector((state) => state.softphoneUsers);
  const softphone = allSoftphone.find((item) => item._id === softphoneId);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const sendAccessCode = (user) => {
    if (!user.email) {
      return;
    }
    axios
      .post("https://api.nevtis.com/comunication/email/access-code", {
        email: user.email,
        token: user.activationCode,
        name: user.name,
        role: user.role,
      })
      .then((res) => {
        res.status === 200
          ? showToast("Access Code Sent", "success")
          : showToast("Error", "error");
      });
  };

  if (!softphone) {
    return <Loading />;
  }

  return (
    <Tabs mt={10} width="100%" isFitted={isLargerThan768} colorScheme="orange">
      <Center>
        <Flex direction={{ base: "column", md: "row" }} align="center">
          <Heading
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight="bold"
            textAlign="center"
            mr={{ base: 0, md: 4 }}
            mb={{ base: 4, md: 0 }}
            color={"nevtis.1"}
          >
            {softphone.name}
          </Heading>
          {user.superAdmin && (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<IoSettingsSharp />}
                variant="outline"
              />
              <MenuList>
                <EditSoftphone user={softphone} />
                <LinkChakra
                  href={`https://clientspace.nevtis.com/${master}/${softphone.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MenuItem>Login as {softphone.name}</MenuItem>
                </LinkChakra>
                <DeleteSoftphone user={softphone} />
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Center>
      <Center mb={4}>
        {softphone.isActivated ? (
          <Flex>
            <Text
              size={"xs"}
              color="green"
              borderRadius="md"
              px="2"
              py="2"
              display={"flex"}
            >
              <MdCheckCircle style={{ marginRight: "2px" }} /> Active
            </Text>
          </Flex>
        ) : (
          <Flex>
            <Text
              size={"xs"}
              color="red"
              borderRadius="md"
              px="2"
              py="2"
              display={"flex"}
            >
              <MdCancel style={{ marginRight: "4px" }} />
              Inactive
            </Text>
          </Flex>
        )}
        {!softphone.isActivated && (
          <Button
            size={"xs"}
            textAlign={"center"}
            bg={"nevtis.1.4"}
            _hover={{ bg: "nevtis.1" }}
            onClick={() => sendAccessCode(softphone)}
          >
            <RiMailSendLine style={{ marginRight: "2px" }} />
            Access Code
          </Button>
        )}
      </Center>
      <TabList overflowX={{ base: "auto", md: "visible" }}>
        <Tab>Details</Tab>
        <Tab>Comunications</Tab>
        <Tab>Activities</Tab>
        <Tab>Tickets</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Box width="100%">
            <SoftphoneDetail user={softphone} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <Comunications user={softphone} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <Activities user={softphone} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">TICKETS</Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default SoftphoneDetailsTabs;
