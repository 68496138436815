import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import TaskChannel from "./TaskChannel";
import AppointmentsChannel from "./AppointmentsChannel";
import MilestonesChannel from "./MilestonesChannel";
import NotesChannel from "./NotesChannel";
import { useMediaQuery } from "@chakra-ui/react";

const Activities = ({ user }) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <Box
      borderRadius={"xl"}
      maxHeight={"450px"}
      width={isLargerThan768 ? "80%" : "100%"}
      m={2}
      flex="1"
      bg="white"
      align="center"
      boxShadow="0 4px 12px 0 rgba(0,0,0,0.5)"
      overflowY="auto"
    >
      <Tabs variant="enclosed">
        <TabList overflowX={isLargerThan768 ? "visible" : "auto"}>
          <Tab color={"nevtis.1"} fontWeight={"bold"}>
            Tasks
          </Tab>
          <Tab color={"nevtis.1"} fontWeight={"bold"}>
            Appointments
          </Tab>
          <Tab color={"nevtis.1"} fontWeight={"bold"}>
            Milestones
          </Tab>
          <Tab color={"nevtis.1"} fontWeight={"bold"}>
            Notes
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TaskChannel user={user} />
          </TabPanel>
          <TabPanel>
            <AppointmentsChannel user={user} />
          </TabPanel>
          <TabPanel>
            <MilestonesChannel user={user} />
          </TabPanel>
          <TabPanel>
            <NotesChannel user={user} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Activities;
