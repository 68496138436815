import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  useDisclosure,
  Select,
  Stack,
  FormLabel,
  Input,
  FormControl,
  Grid,
  GridItem,
  ListItem,
  List,
  Text,
  Box,
  Flex,
  MenuItem,
} from "@chakra-ui/react";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios";

const AddVtext = ({ user }) => {
  const { register, reset, handleSubmit } = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [vtextByUser, setVtextByUser] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.nevtis.com/user/users/user/${user._id}`)
      .then((res) => {
        setVtextByUser(res.data.vText);
      });
  }, [user._id]);

  const onSubmit = async (data) => {
    try {
      await axios.put(
        `https://api.nevtis.com/user/users/addVtext/${user._id}`,
        data
      );
      setVtextByUser([...vtextByUser, data]);
      reset();
    } catch (error) {
      console.error("Error al agregar vText:", error);
    }
  };

  const handleDelete = async (item) => {
    try {
      await axios.put(
        `https://api.nevtis.com/user/users/addVtext/${user._id}`,
        item
      );
      const updatedVtext = vtextByUser.filter(
        (vtext) => vtext._id !== item._id
      );
      setVtextByUser(updatedVtext);
    } catch (error) {
      console.error("Error al eliminar vText:", error);
    }
  };
  const deleteIconStyles = {
    cursor: "pointer",
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.2)",
    },
  };

  return (
    <>
      <MenuItem onClick={onOpen}>V-Text </MenuItem>

      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size={"lg"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            borderBottomWidth="1px"
            textAlign={"center"}
            color={"nevtis.1"}
          >
            Vtext SMS
          </DrawerHeader>
          <DrawerBody mt={10}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <Grid templateColumns="repeat(6, 1fr)" gap={2} mb={20}>
                  <GridItem colSpan={3}>
                    <FormControl isRequired>
                      <FormLabel textAlign={"center"} color={"nevtis.1"}>
                        Email
                      </FormLabel>
                      <Input {...register("email")} type="email" />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <FormControl isRequired>
                      <FormLabel textAlign={"center"} color={"nevtis.1"}>
                        Phone
                      </FormLabel>
                      <Flex>
                        <Select defaultValue="+1" maxWidth={"70px"}>
                          <option value="+1">+1</option>
                        </Select>
                        <Input {...register("phone")} type={"tel"} />
                      </Flex>
                    </FormControl>
                  </GridItem>
                </Grid>
                <Box mt={12}>
                  <Text
                    borderBottomWidth="1px"
                    mb={22}
                    fontSize={20}
                    textAlign={"center"}
                    color={"nevtis.1"}
                  >
                    Assigned Services
                  </Text>
                  <Grid templateColumns="repeat(6, 1fr)" gap={2}>
                    <GridItem colSpan={3}>
                      <Text textAlign={"center"} color={"nevtis.1"}>
                        Email
                      </Text>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Text textAlign={"center"} color={"nevtis.1"}>
                        Phone
                      </Text>
                    </GridItem>
                    <GridItem colSpan={1}></GridItem>
                    {vtextByUser.map((item) => (
                      <React.Fragment key={item._id}>
                        <GridItem colSpan={3}>
                          <List>
                            <ListItem textAlign={"center"}>
                              {item.email}
                            </ListItem>
                          </List>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <List>
                            <ListItem textAlign={"center"}>
                              {item.phone}
                            </ListItem>
                          </List>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <List>
                            <ListItem textAlign={"center"} mt={1}>
                              <AiFillDelete
                                color="red"
                                onClick={() => handleDelete(item)}
                                style={deleteIconStyles} // Agrega el estilo css al ícono
                              />
                            </ListItem>
                          </List>
                        </GridItem>
                      </React.Fragment>
                    ))}
                  </Grid>
                </Box>
                <Stack spacing={30}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={"start"}
                    justify={"space-between"}
                  ></Stack>
                  <Button
                    type="submit"
                    bg={"rgba(255,134,0)"}
                    color={"black"}
                    _hover={{
                      bg: "rgba(255,134,0,0.5)",
                    }}
                  >
                    Add
                  </Button>
                </Stack>
              </Stack>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddVtext;
