import { URL_INTERACTIONS } from "../config/apiBaseURL";
export const getAllActions = async (client) => {
  const actions = await fetch(
    `${URL_INTERACTIONS}/activities/getByEmail/${client}`
  );
  const actionsJson = await actions.json();
  return actionsJson;
};

export const getUserMessages = async (clientId) => {};

export const addMessage = async (messageData) => {};

export const getMessageArray = async (client) => {
  const actions = await getAllActions(client);
  const notesArray = actions.notes.map((note) => {
    return note.text;
  });
  return notesArray;
};
