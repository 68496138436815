import React from "react";
import { Box } from "@chakra-ui/react";
import nevtisLogo from "../../assets/logo.png";

const Loading = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="50vh"
    >
      <img src={nevtisLogo} alt="Nevtis Logo" width="200" height="200" />
    </Box>
  );
};

export default Loading;
