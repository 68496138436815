import { Box, Center, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { FaClock, FaPhone, FaUser } from "react-icons/fa";

const CallsList = ({ llamadasFiltradas, limite, fullscreen }) => {
  const llamadas = llamadasFiltradas.map((item) => {
    let nombre;
    switch (item.ext) {
      case "2120":
        nombre = "Juan O";
        break;
      case "2160":
        nombre = "Niko Jaimes";
        break;
      case "2182":
        nombre = "Jennifer L";
        break;
      case "2183":
        nombre = "Miguel N";
        break;
      case "2185":
        nombre = "Carlos S";
        break;
      default:
        nombre = "Desconocido";
    }

    // Retorna un nuevo objeto con la propiedad 'ext' reemplazada por el nombre
    return { ...item, ext: nombre };
  });
  function formatDuration(seconds) {
    const minutos = Math.floor(seconds / 60);
    const segundosRestantes = seconds % 60;

    const formatoMinutos = minutos < 10 ? `0${minutos}` : minutos;
    const formatoSegundos =
      segundosRestantes < 10 ? `0${segundosRestantes}` : segundosRestantes;

    return `${formatoMinutos}:${formatoSegundos}`;
  }

  return (
    <Box maxHeight={fullscreen ? "420px" : "290px"} overflowY={"auto"}>
      {llamadas.slice(0, limite).map((llamada, index) => (
        <Center key={index} mt={1}>
          <Flex align="center" color="nevtis.1" fontWeight="bold" mb={2}>
            <FaUser style={{ marginLeft: "0.5rem", marginRight: "0.2rem" }} />
            <Text fontSize={fullscreen ? "lg" : "md"}>{llamada.ext} |</Text>
            <FaPhone style={{ marginLeft: "0.5rem", marginRight: "0.2rem" }} />
            <Text fontSize={fullscreen ? "lg" : "md"}>
              {llamada.caller_destination} |
            </Text>
            <FaClock style={{ marginLeft: "0.5rem", marginRight: "0.2rem" }} />
            <Text fontSize={fullscreen ? "lg" : "md"}>
              {formatDuration(llamada.duration)}
            </Text>
          </Flex>
        </Center>
      ))}
    </Box>
  );
};

export default CallsList;
