import React from "react";
import { Route, Routes } from "react-router-dom";
import { Flex, useMediaQuery } from "@chakra-ui/react";
import Sidebar from "../components/general/Sidebar";
import Settings from "../components/general/Settings";
import AdminRoutes from "./AdminRoutes";
import HomeChat from "../components/chat/HomeChat";
const UserActive = ({ user }) => {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  return (
    <>
      <Flex>
        {isDesktop && <Sidebar />}
        <Routes>
          <Route path="/settings" element={<Settings />} />
          <Route path="/chat" element={<HomeChat />} />
        </Routes>
        {user.role === "admin" && <AdminRoutes user={user} />}
      </Flex>
    </>
  );
};

export default UserActive;
