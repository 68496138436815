import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  FormControl,
  Button,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getAllActions } from "../../API/actionsAPI";
import { BsTrash } from "react-icons/bs";
import "./NotesChannel.css";
import { URL_INTERACTIONS } from "../../config/apiBaseURL";

const NotesChannel = ({ user }) => {
  const { register, handleSubmit, setValue } = useForm();
  const queryNotes = useQuery({
    queryKey: ["notes", user.email],
    queryFn: ({ queryKey }) => getAllActions(queryKey[1]), //queryKey[1] is the user email
  });

  const onSubmitNote = async (data) => {
    const sendMessage = {
      email: user.email,
      notes: {
        text: data.note,
      },
    };
    const response = await fetch(`${URL_INTERACTIONS}/activities/create`, {
      method: "POST",
      body: JSON.stringify(sendMessage),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 201) {
    } else {
    }
    setValue("note", "");
    queryNotes.refetch();
  };

  const deleteNote = async (noteId) => {
    const response = await fetch(
      `${URL_INTERACTIONS}/activities/delete/${user.email}/notes/${noteId}`,
      {
        method: "DELETE",
      }
    );

    if (response.status === 200) {
      queryNotes.refetch();
    } else {
    }
  };

  const handleDeleteNote = async (noteId) => {
    await deleteNote(noteId);
  };

  if (queryNotes.isLoading) {
    return <div>Loading...</div>;
  }

  if (queryNotes.isError) {
    return <div>No data, contact Support</div>;
  }

  return (
    <Box height="100%" width="100%" p={4}>
      <Box>
        <form onSubmit={handleSubmit(onSubmitNote)}>
          <FormControl border="solid 3px white" borderRadius="md">
            <Textarea
              placeholder="Add a Note"
              {...register("note", { required: true })}
              bg="white"
              resize="none"
              minHeight="30px"
            />
            <Button type="submit" colorScheme="orange">
              Add
            </Button>
          </FormControl>
        </form>
      </Box>
      <Box mt={4} style={{ height: "32vh", overflow: "auto" }}>
        {queryNotes.data.length === 0 ? (
          <Text color={"nevtis.1"} fontWeight={"bold"} mt={10}>
            No notes yet
          </Text>
        ) : (
          queryNotes.data[0].notes.map((note) => (
            <Flex
              key={note._id}
              align="center"
              bg="orange.50"
              p={2}
              my={2}
              borderRadius="md"
            >
              <Box
                flex="1"
                px={2}
                py={1}
                className="note"
                boxShadow="xl"
                bg="orange.100"
              >
                {note.text}
              </Box>
              <Button
                colorScheme="orange"
                ml={1}
                boxShadow="lg"
                variant="outline"
                size="xs"
                onClick={() => handleDeleteNote(note._id)}
              >
                <BsTrash />
              </Button>
            </Flex>
          ))
        )}
      </Box>
    </Box>
  );
};

export default NotesChannel;
