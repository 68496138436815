import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { Box, Heading } from "@chakra-ui/react";

const SellerGraph = ({ count, tittle }) => {
  const COLORS = ["rgba(255,134,0,0.8)", "rgba(255,134,0,0.5)"];

  const data = [{ name: `${tittle}`, value: count }];

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        fontSize={"20px"}
        fontWeight={"bold"}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {value}
      </text>
    );
  };

  return (
    <Box width="100%" height={260} textAlign="center">
      <Heading color={"gray.500"} size={"lg"}>
        {tittle} ({count})
      </Heading>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            label={renderCustomizedLabel}
            labelLine={false}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default SellerGraph;
