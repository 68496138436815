import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
const TableExtensions = ({ extensionesAsignadas }) => {
  return (
    <Box>
      <TableContainer mt={4} maxH="450px" overflowY="auto">
        <Table size="sm">
          <Thead position="sticky" top={0} zIndex="sticky" bg="white">
            <Tr>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Ext
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Effective CID Name
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Outbound Name
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Outbound Number
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Softphone User
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {extensionesAsignadas.map((ext, index) => (
              <Tr key={index}>
                <Td textAlign={"center"}>{ext.extension_number}</Td>
                <Td textAlign={"center"}>{ext.effective_caller_id_name}</Td>
                <Td textAlign={"center"}>{ext.outbound_caller_id_name}</Td>
                <Td textAlign={"center"}>{ext.outbound_caller_id_number}</Td>
                {ext.softphone_user && ext.softphone_user.email ? (
                  <Td textAlign={"center"}>
                    <Link to={`/softphone-user/${ext.softphone_user._id}`}>
                      <Text color={"nevtis.1"} as={"u"} fontWeight={"bold"}>
                        {ext.softphone_user.name.toUpperCase()}
                      </Text>
                    </Link>
                  </Td>
                ) : (
                  <Td textAlign={"center"}>
                    <Badge colorScheme="green">Available</Badge>
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableExtensions;
