const useChartDataAndCalls = (calls) => {
  const conteoExtensiones = {};

  calls.forEach((llamada) => {
    const { ext } = llamada;
    conteoExtensiones[ext] = (conteoExtensiones[ext] || 0) + 1;
  });

  const statItems = [
    {
      label: "Juan O.",
      value: conteoExtensiones[2120] || 0,
    },
    {
      label: "Niko J.",
      value: conteoExtensiones[2160] || 0,
    },
    {
      label: "Jennifer L.",
      value: conteoExtensiones[2182] || 0,
    },
    {
      label: "Miguel N.",
      value: conteoExtensiones[2183] || 0,
    },
    {
      label: "Carlos S.",
      value: conteoExtensiones[2185] || 0,
    },
  ];

  const chartData = statItems.map((item) => ({
    name: item.label,
    value: item.value,
  }));

  const totalCalls = chartData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.value,
    0
  );

  const extensionesFiltradas = ["2182", "2183", "2160", "2185", "2120"];
  const llamadasFiltradas = calls.filter((llamada) =>
    extensionesFiltradas.includes(llamada.ext)
  );

  return { chartData, totalCalls, extensionesFiltradas, llamadasFiltradas };
};

export default useChartDataAndCalls;
