import React, { useEffect, useState } from "react";
import { Text, Box, Flex, Center } from "@chakra-ui/react";
import { FaRegTimesCircle, FaRegCheckCircle } from "react-icons/fa";

import axios from "axios";
const StatusDigitalOcean = () => {
  const [statusDigitalOcean, setStatusDigitalOcean] = useState([]);

  useEffect(() => {
    axios
      .get("https://status.digitalocean.com/api/v2/summary.json")
      .then((res) => {
        setStatusDigitalOcean(res.data);
      });
  }, []);
  const statusGlobal =
    statusDigitalOcean.components?.filter(
      (item) => item.group_id === null && item.group === false
    ) || [];
  const statusRegional =
    statusDigitalOcean.components?.filter(
      (item) => item.group_id === null && item.group === true
    ) || [];

  const hasOperationalStatusGlobal = statusGlobal.some(
    (item) => item.status === "operational"
  );
  const hasOperationalStatusRegional = statusRegional.some(
    (item) => item.status === "operational"
  );
  return (
    <Flex boxShadow={"sm"} borderRadius={"lg"} py={1} px={4}mr={10} bg={"rgba(255,134,0,0.03)"}>
      <Text textAlign={"center"} fontSize={"sm"} mt={2} mr={5}>
        Digital Ocean Services
      </Text>
      <Center>
        <Box>
          <Flex
            textAlign={"center"}
            flexDirection={{ base: "column", md: "row" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize={"sm"} flex="1" mr={{ md: 2 }}>
              Global
            </Text>
            <Box alignItems={"flex-end"}>
              {hasOperationalStatusGlobal ? (
                <FaRegCheckCircle color="green" fontSize={14} />
              ) : (
                <FaRegTimesCircle color="red" fontSize={14} />
              )}
            </Box>
          </Flex>
          <Flex
            textAlign={"center"}
            flexDirection={{ base: "column", md: "row" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize={"sm"} flex="1" mr={{ md: 2 }}>
              Regional
            </Text>
            <Box alignItems={"flex-end"}>
              {hasOperationalStatusRegional ? (
                <FaRegCheckCircle color="green" fontSize={14} />
              ) : (
                <FaRegTimesCircle color="red" fontSize={14} />
              )}
            </Box>
          </Flex>
        </Box>
      </Center>
    </Flex>
  );
};

export default StatusDigitalOcean;
