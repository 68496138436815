import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import {
  getMessagesByChatId,
  updateOnlineUsers,
  updateCurrentChat,
  updateNotifications,
  findUserChats,
} from "../../store/reducers/chat.reducer";
import { useLocation } from "react-router-dom";
import Noti from "../../assets/audio/notification.mp3";
import { SOCKET } from "../../config/apiBaseURL";

const SocketListener = ({ user }) => {
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();
  const currentChat = useSelector((state) => state.chats.currentChat);
  const chats = useSelector((state) => state.chats.chats);
  const currentChatRef = useRef(currentChat);
  const location = useLocation();
  const [isNewNotification, setIsNewNotification] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/chat" && currentChat !== null) {
      dispatch(updateCurrentChat(null));
    }
  }, [location.pathname, currentChat, dispatch]);

  useEffect(() => {
    currentChatRef.current = currentChat;
  }, [currentChat]);

  useEffect(() => {
    const newSocket = io(`${SOCKET}`);
    setSocket(newSocket);
    return () => {
      newSocket.disconnect();
    };
  }, [user]);

  useEffect(() => {
    if (socket === null) return;

    socket.on("getOnlineUsers", (res) => {
      dispatch(updateOnlineUsers(res));
    });

    socket.on("getMessage", (message) => {
      const chatId = currentChatRef.current?._id;
      if (chatId === message.chatId) {
        dispatch(getMessagesByChatId(message.chatId));
      } else {
        const chatExists = chats.some((chat) => chat._id === message.chatId);
        if (!chatExists) {
          dispatch(findUserChats(user._id));
        }
      }
    });

    socket.on("getNotifications", (notification) => {
      const isChatOpen = currentChatRef.current?.members?.includes(
        notification.senderId
      );
      if (!isChatOpen) {
        dispatch(updateNotifications(notification));
        setIsNewNotification(true);
      }
    });

    socket.emit("addNewUser", user?._id);

    return () => {
      socket.off("getMessage");
      socket.off("getNotifications");
    };
  }, [socket, dispatch, user?._id, chats]); // Agrega 'chats' al array de dependencias

  useEffect(() => {
    if (isNewNotification) {
      const audio = new Audio(Noti);
      audio.play(); // Reproduce el audio cuando hay una nueva notificación
    }
  }, [isNewNotification]);

  return null;
};

export default SocketListener;
