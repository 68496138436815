import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Flex,
  Box,
  Image,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import logo from "../assets/logo1.png";

const MenuMobile = ({ user, path, logout }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const handleLinkClick = () => {
    onClose();
  };

  return (
    <>
      <IconButton
        ml={5}
        icon={<HamburgerIcon />}
        variant={"outline"}
        size={"md"}
        aria-label="Open menu"
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Link to={"/"}>
              <Box>
                <Image src={logo} height="12" p={2} />
              </Box>
            </Link>
          </DrawerHeader>

          <DrawerBody>
            <Flex
              p="5%"
              flexDir="column"
              alignItems={"center"}
              as="nav"
              mt={10}
            >
              <Link to={"/"} onClick={handleLinkClick}>
                <Text
                  fontSize={20}
                  mt={2}
                  textAlign="center"
                  color={"nevtis.1"}
                  fontWeight={"bold"}
                >
                  Home
                </Text>
              </Link>

              {user.role === "admin" && (
                <>
                  <Link to={"/client"} onClick={handleLinkClick}>
                    <Text
                      fontSize={20}
                      mt={2}
                      textAlign="center"
                      color={"nevtis.1"}
                      fontWeight={"bold"}
                    >
                      Customers
                    </Text>
                  </Link>

                  <Link to={"/admin"} onClick={handleLinkClick}>
                    <Text
                      fontSize={20}
                      mt={2}
                      textAlign="center"
                      color={"nevtis.1"}
                      fontWeight={"bold"}
                    >
                      Admins
                    </Text>
                  </Link>

                  <Link to={"/seller"} onClick={handleLinkClick}>
                    <Text
                      fontSize={20}
                      mt={2}
                      textAlign="center"
                      color={"nevtis.1"}
                      fontWeight={"bold"}
                    >
                      Sellers
                    </Text>
                  </Link>

                  <Link to={"/schedule"} onClick={handleLinkClick}>
                    <Text
                      fontSize={20}
                      mt={2}
                      textAlign="center"
                      color={"nevtis.1"}
                      fontWeight={"bold"}
                    >
                      Service Calls
                    </Text>
                  </Link>
                  <Link to={"/marketplace"} onClick={handleLinkClick}>
                    <Text
                      fontSize={20}
                      mt={2}
                      textAlign="center"
                      color={"nevtis.1"}
                      fontWeight={"bold"}
                    >
                      Marketplace
                    </Text>
                  </Link>

                  <Link to={"/service"} onClick={handleLinkClick}>
                    <Text
                      fontSize={20}
                      mt={2}
                      textAlign="center"
                      color={"nevtis.1"}
                      fontWeight={"bold"}
                    >
                      Services
                    </Text>
                  </Link>
                </>
              )}

              {user.role === "seller" && (
                <>
                  <Link to={"/client"} onClick={handleLinkClick}>
                    <Text
                      fontSize={20}
                      mt={2}
                      textAlign="center"
                      color={"nevtis.1"}
                      fontWeight={"bold"}
                    >
                      Clients
                    </Text>
                  </Link>

                  <Link to={"/service"} onClick={handleLinkClick}>
                    <Text
                      fontSize={20}
                      mt={2}
                      textAlign="center"
                      color={"nevtis.1"}
                      fontWeight={"bold"}
                    >
                      Services
                    </Text>
                  </Link>
                  <Link to={"/proposar"} onClick={handleLinkClick}>
                    <Text
                      fontSize={20}
                      mt={2}
                      textAlign="center"
                      color={"nevtis.1"}
                      fontWeight={"bold"}
                    >
                      Proposars
                    </Text>
                  </Link>
                </>
              )}

              {user.role === "client" && (
                <>
                  <Link to={"/softphoneUsers"} onClick={handleLinkClick}>
                    <Text
                      fontSize={20}
                      mt={2}
                      textAlign="center"
                      color={"nevtis.1"}
                      fontWeight={"bold"}
                    >
                      Softphone Users
                    </Text>
                  </Link>

                  {/*                   <Link to={"/services"} onClick={handleLinkClick}>
                    <Text
                      fontSize={20}
                      mt={2}
                      textAlign="center"
                      color={"nevtis.1"}
                      fontWeight={"bold"}
                    >
                      Services
                    </Text>
                  </Link> */}
                </>
              )}

              {user.role === "softphoneUsers" && (
                <Link to={"/quickdial"} onClick={handleLinkClick}>
                  <Text
                    fontSize={20}
                    mt={2}
                    textAlign="center"
                    color={"nevtis.1"}
                    fontWeight={"bold"}
                  >
                    QuickDial
                  </Text>
                </Link>
              )}

              <Link to={"/settings"} onClick={handleLinkClick}>
                <Text
                  fontSize={20}
                  mt={2}
                  textAlign="center"
                  color={"nevtis.1"}
                  fontWeight={"bold"}
                >
                  Settings
                </Text>
              </Link>
            </Flex>
          </DrawerBody>
          <DrawerFooter>
            <Button
              variant={"outline"}
              colorScheme="orange"
              onClick={logout}
              size="md"
              w="100%"
            >
              Logout
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MenuMobile;
