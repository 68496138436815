import React from "react";
import { useParams } from "react-router-dom";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Heading,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Button,
  Link as LinkChakra,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import CustomerDetail from "./CustomerDetail";
import { useSelector } from "react-redux";
import Comunications from "../../Actions/Comunications";
import Activities from "../../Actions/Activities";
import Loading from "../../../commons/general/Loading";
import { IoSettingsSharp } from "react-icons/io5";
import EditCustomer from "./EditCustomer";
import DeleteCustomer from "./DeleteCustomer";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { RiMailSendLine } from "react-icons/ri";
import NevtisPbx from "./NevtisPbx";
import { showToast } from "../../../commons/SweetAlertToast";
import axios from "axios";
import AddVtext from "./AddVtext";

const CustomerDetailsTabs = () => {
  const userLocalStorg = JSON.parse(localStorage.getItem("user"));
  const user = useSelector((state) => state.user);
  const master = userLocalStorg.token;
  const { customerId } = useParams();
  const allCustomers = useSelector((state) => state.clients);
  const customer = allCustomers.find((item) => item._id === customerId);
  const allExtensions = useSelector((state) => state.extensions);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const sendAccessCode = (user) => {
    if (!user.email) {
      return;
    }
    axios
      .post("https://api.nevtis.com/comunication/email/access-code", {
        email: user.email,
        token: user.activationCode,
        name: user.name,
        role: user.role,
      })
      .then((res) => {
        res.status === 200
          ? showToast("Access Code Sent", "success")
          : showToast("Error", "error");
      });
  };
  if (!customer) {
    return <Loading />;
  }
  const extensionsByCustomer =
    customer && customer.domain
      ? allExtensions.extensions.filter(
          (item) => item.domain_uuid === customer.domain.domain_uuid
        )
      : [];
  return (
    <Tabs mt={4} width="100%">
      <Center>
        <Flex direction={isLargerThan768 ? "row" : "column"} align="center">
          <Heading
            fontSize={isLargerThan768 ? "4xl" : "2xl"}
            fontWeight="bold"
            textAlign="center"
            mr={isLargerThan768 ? "4" : "0"}
            mb={isLargerThan768 ? "0" : "4"}
            color={"nevtis.1"}
          >
            {customer.companyName}
          </Heading>
          {user.superAdmin && (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<IoSettingsSharp />}
                variant="outline"
              />
              <MenuList>
                <EditCustomer user={customer} />
                <LinkChakra
                  href={`https://clientspace.nevtis.com/${master}/${customer.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MenuItem>Login as {customer.companyName}</MenuItem>
                </LinkChakra>

                <AddVtext user={customer} />
                <DeleteCustomer user={customer} />
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Center>
      <Center mb={1}>
        {customer.isActivated ? (
          <Flex>
            <Text
              size={"xs"}
              color="green"
              borderRadius="md"
              px="2"
              py="2"
              display={"flex"}
            >
              <MdCheckCircle style={{ marginRight: "2px" }} /> Active
            </Text>
          </Flex>
        ) : (
          <Flex>
            <Text
              size={"xs"}
              color="red"
              borderRadius="md"
              px="2"
              py="2"
              display={"flex"}
            >
              <MdCancel style={{ marginRight: "4px" }} />
              Inactive
            </Text>
          </Flex>
        )}
        {!customer.isActivated && (
          <Button
            size={"xs"}
            textAlign={"center"}
            bg={"nevtis.1.4"}
            _hover={{ bg: "nevtis.1" }}
            onClick={() => sendAccessCode(customer)}
            ml={isLargerThan768 ? "4" : "0"}
            mt={isLargerThan768 ? "0" : "2"}
          >
            <RiMailSendLine style={{ marginRight: "2px" }} />
            Access Code
          </Button>
        )}
      </Center>
      <TabList overflowX="auto">
        <Tab>Details</Tab>
        <Tab>Nevtis PBX</Tab>
        <Tab>Comunications</Tab>
        <Tab>Activities</Tab>
        <Tab>Tickets</Tab>
        <Tab>Quotes</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Box width="100%">
            <CustomerDetail
              customer={customer}
              extensionsByCustomer={extensionsByCustomer}
            />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <NevtisPbx
              customer={customer}
              extensionsByCustomer={extensionsByCustomer}
            />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <Comunications user={customer} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <Activities user={customer} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">TICKETS</Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">QUOTES</Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default CustomerDetailsTabs;
