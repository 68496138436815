import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { MdDelete } from "react-icons/md";
import Pagination from "../../../commons/general/Pagination";
import axios from "axios";
import { FaFilePdf } from "react-icons/fa";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const TableQuotes = () => {
  const [promotions, setPromotions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [isDesktop] = useMediaQuery("(min-width: 800px)");

  useEffect(() => {
    axios
      .get("https://api.nevtis.com/marketplace/quotes/all")
      .then((res) => {
        const sortedPromotions = res.data.sort((a, b) => a.quoteNo - b.quoteNo);
        setPromotions(sortedPromotions);
      })
      .catch((error) => {
        console.error("Error fetching promotions:", error);
      });
  }, []);

  const handleDeletePromotions = async () => {
    try {
      await axios.delete(
        `https://api.nevtis.com/marketplace/quotes/delete/${selectedItem._id}`
      );
      setPromotions((prevCategories) =>
        prevCategories.filter((cat) => cat._id !== selectedItem._id)
      );
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting Promotion:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleInputPerPage = (value) => {
    setItemsPerPage(Number(value));
  };

  const filteredData = promotions.filter((item) =>
    item?.company?.companyName
      ?.toLowerCase()
      .includes(searchValue.toLowerCase())
  );

  const sections = filteredData.reduce(
    (acc, item, index) =>
      index % itemsPerPage === 0
        ? [...acc, filteredData.slice(index, index + itemsPerPage)]
        : acc,
    []
  );

  const currentSection = sections[currentPage - 1];

  return (
    <Box mt={4} mx={10}>
      <Stack direction={{ base: "column", md: "row" }} spacing={10} mb={4}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by Company Name"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <Flex justifyContent="flex-end" alignItems="center">
          <Text color={"nevtis.1"} mr={2}>
            Items per Page
          </Text>
          <NumberInput
            size="xs"
            maxW={16}
            defaultValue={itemsPerPage}
            min={1}
            onChange={handleInputPerPage}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Stack>

      <TableContainer mt={6} maxHeight={"280px"} overflowY="scroll">
        <Table variant="simple" size={isDesktop ? "2xl" : "xs"}>
          <Thead
            position={"sticky"}
            zIndex={1}
            top={0}
            bg={"white"}
            fontSize={!isDesktop && "xs"}
            textAlign={!isDesktop && "center"}
          >
            <Tr>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Nº
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Company Name
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Email
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Phone
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Seller
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Status
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentSection &&
              currentSection.map((item) => (
                <Tr key={item._id}>
                  <Td textAlign="center">{item.quoteNo}</Td>
                  <Td textAlign="center">{item.company.companyName}</Td>
                  <Td textAlign="center">{item.company.email}</Td>
                  <Td textAlign="center">{item.company.phone}</Td>
                  <Td textAlign="center">seller</Td>
                  <Td textAlign="center">{item.status}</Td>

                  <Td textAlign="center">
                    <IconButton
                      isDisabled={!item.pdf || !item.pdf.endsWith(".pdf")}
                      px={1}
                      bg="rgba(255,134,0)"
                      _hover={{ bg: "rgba(255,134,0,0.7)" }}
                      icon={<FaFilePdf color="white" fontSize={"20px"} />}
                      size="xs"
                      mr={4}
                      onClick={() => {
                        setSelectedItem(item);
                        setIsPdfModalOpen(true);
                      }}
                    />
                    <IconButton
                      icon={<MdDelete color="white" fontSize={"20px"} />}
                      px={1}
                      colorScheme="red"
                      size="xs"
                      onClick={() => {
                        setSelectedItem(item);
                        setIsDeleteModalOpen(true);
                      }}
                    />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        sections={sections}
        currentPage={currentPage}
        handlePageChange={setCurrentPage}
      />

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Promotion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this promotion?</Text>
            <Button mt={4} colorScheme="red" onClick={handleDeletePromotions}>
              Confirm Delete
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isPdfModalOpen}
        onClose={() => setIsPdfModalOpen(false)}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="nevtis.1" textAlign="center">
            {" "}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedItem && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <div style={{ height: "600px" }}>
                  <Viewer
                    fileUrl={`https://api.nevtis.com/marketplace/files/list/${selectedItem.pdf}`}
                  />
                </div>
              </Worker>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TableQuotes;
