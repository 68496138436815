import React, { useEffect, useState } from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { Box, Heading, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { URL_FUSIONPBX } from "../../../config/apiBaseURL";

const CallsGraph = () => {
  const { customerId } = useParams();
  const allCustomers = useSelector((state) => state.clients);
  const customer = allCustomers.find((item) => item._id === customerId);
  const [calls, setCalls] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${URL_FUSIONPBX}/calls/getCallsByDomain/${customer.domain.domain_uuid}`
      )
      .then((resp) => {
        setCalls(resp.data);
      })
      .catch((error) => {
        setError("No data, please contact support");
      });
  }, [customer.domain.domain_uuid]);
  const today = new Date().toISOString().split("T")[0];

  const callsToday = calls.filter((llamada) =>
    llamada.start_stamp.startsWith(today)
  );

  const COLORS = ["rgba(255,134,0,0.8)"];
  const data = [{ name: "Assigned", value: callsToday.length }];

  if (error) {
    return (
      <Text textAlign={"center"} color={"nevtis.1"} fontWeight={"bold"} mt={5}>
        {error}
      </Text>
    );
  }

  return (
    <Box width="100%" height={260} textAlign="center">
      <Heading color={"gray.500"} size={"lg"}>
        Calls Today
      </Heading>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel()}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default CallsGraph;

const renderCustomizedLabel =
  () =>
  ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        dominantBaseline="central"
        fontSize={"20px"}
        fontWeight={"bold"}
      >
        {`${value}`}
      </text>
    );
  };
