import React, { useState } from "react";
import {
  Stack,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Input,
  FormControl,
  Button,
  Box,
  useDisclosure,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import axios from "axios";
import { showToast } from "../../../commons/SweetAlertToast";

const CreateAdvertisement = ({ setAdvertisement }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({
    isVisible: true,
    title: "",
    message: "",
  });
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  const submit = async () => {
    for (const key in formData) {
      if (formData[key] === "" && key !== "isVisible") {
        setError(`Please complete all fields`);
        return;
      }
    }
    try {
      const response = await axios.post(
        "https://api.nevtis.com/marketplace/advertisement/create",
        formData
      );
      setAdvertisement((prevMessages) => [...prevMessages, response.data]);
      showToast("Message created", "success");
      setFormData({
        isVisible: true,
        title: "",
        message: "",
        createdAt: new Date().toISOString(),
      });
      onClose();
    } catch (error) {
      console.error("Error creating Message:", error);
      setError("Failed to create Message");
    }
  };

  return (
    <>
      <Center mt={4}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          bg="rgb(255,134,0)"
          _hover={{ bg: "rgba(255,134,0,0.5)" }}
          onClick={onOpen}
          size="xs"
        >
          Add Message
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            textAlign={"center"}
            color={"nevtis.1"}
            fontSize={"2xl"}
            fontWeight={"bold"}
          >
            Create a New Message
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ width: "100%" }}>
              <Stack spacing={2} width={"full"}>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Title</FormLabel>
                  <Input
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Message</FormLabel>
                  <Input
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel style={formLabelStyle}>Visible</FormLabel>
                  <Checkbox
                    name="isVisible"
                    isChecked={formData.isVisible}
                    onChange={handleCheckboxChange}
                  >
                    Show message
                  </Checkbox>
                </FormControl>
              </Stack>
            </Box>

            <Text color="red">{error}</Text>
            <Center mt={10} mb={4}>
              <Button
                onClick={submit}
                type="submit"
                bg={"nevtis.1"}
                _hover={{ bg: "nevtis.1.4" }}
                color={"white"}
              >
                Submit
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const formLabelStyle = {
  color: "rgba(255,134,0)",
  fontWeight: "bold",
};

export default CreateAdvertisement;
