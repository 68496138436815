import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import TableCustomers from "./TableCustomers";

const ClientTabs = () => {
  const customer = useSelector((state) => state.clients);
  const client = customer.filter(
    (index) => !index.leads || index.leads === undefined
  );

  return (
    <Tabs mt={10} colorScheme="orange" variant="soft-rounded" width={"100%"}>
      <TabList>
        <Tab color={"nevtis.1"}>Client</Tab>
{/*         <Tab color={"nevtis.1"}>Leads</Tab> */}
      </TabList>
      <TabPanels>
        <TabPanel>
          <TableCustomers data={client} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ClientTabs;
