import React from "react";
import { Flex } from "@chakra-ui/react";
import SellerGraph from "./SellerGraph";

const SellerDashboard = () => {
  return (
    <Flex mt={14}>
      <SellerGraph count={20} tittle={"Customers"} />
      <SellerGraph count={12} tittle={"Leads"} />
      <SellerGraph count={14} tittle={"Sub-Vendors"} />
      <SellerGraph count={2856} tittle={"Comission"} />
    </Flex>
  );
};

export default SellerDashboard;
