import React from 'react'
import { Box,FormLabel,Input,Button } from '@chakra-ui/react'
import { MdRestartAlt } from 'react-icons/md'

const ServiceCallFilters = ({filterName,handleFilterNameChange,filterCity,handleFilterCityChange,filterStartDate,handleFilterStartDateChange,filterEndDate,handleFilterEndDateChange,handleReset}) => {
  return (
        
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
          <Box>
            <FormLabel>Filter: by Name</FormLabel>
            <Input value={filterName} onChange={handleFilterNameChange} size='sm'/>
          </Box>
          <Box>
            <FormLabel>by City:</FormLabel>
            <Input value={filterCity} onChange={handleFilterCityChange} size='sm'/>
          </Box>
          <Box>
            <FormLabel>Start Date:</FormLabel>
            <Input
              type="date"
              value={filterStartDate}
              onChange={handleFilterStartDateChange}
              size='sm'
            />
          </Box>
          <Box>
            <FormLabel>End Date:</FormLabel>
            <Input type="date" value={filterEndDate} onChange={handleFilterEndDateChange} size='sm'/>
          </Box>
          <Box>
            <Button onClick={handleReset} size='md' colorScheme='orange' variant='outline' ml={1}><MdRestartAlt/></Button>
          </Box>
        </Box>
  )
}

export default ServiceCallFilters