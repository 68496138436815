import axios from "axios";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import {URL_USER} from "../../config/apiBaseURL";

const initialState = [];

export const getAllProposar = createAsyncThunk("GET_PROPOSAR", () => {
  return axios
    .get(`${{URL_USER}}/users/proposar/all`)
    .then((r) => r.data);
});

export const getProposarById = createAsyncThunk(
  "GET_PROPOSAR",
  (proposarId) => {
    return axios
      .get(`${{URL_USER}}/users/user/${proposarId}`)
      .then((r) => r.data);
  }
);

export const deleteProposar = createAsyncThunk(
  "DELETE_PROPOSAR",
  async (proposarId) => {
    const response = await axios.delete(
      `${{URL_USER}}/users/proposar/delete/${proposarId}`
    );
    return response.data;
  }
);

export const updateProposar = createAsyncThunk(
  "UPDATE_PROPOSAR",
  async (data) => {
    const response = await axios.put(
      `${{URL_USER}}/users/client/update`,
      data
    );
    return response.data;
  }
);

export const createProposar = createAsyncThunk(
  "CREATE_PROPOSAR",
  async (data) => {
    const response = await axios.post(
      `${{URL_USER}}/users/proposar/create`,
      data
    );
    return response.data;
  }
);

export const setProposar = createAction("SET_PROPOSAR");

const proposarReducer = createReducer(initialState, {
  [getAllProposar.fulfilled]: (state, action) => action.payload,
  [deleteProposar.fulfilled]: (state, action) => {
    const { acknowledged, deletedCount } = action.payload;
    if (acknowledged && deletedCount === 1) {
      const filteredProposars = state.filter(
        (proposar) => proposar._id !== action.meta.arg
      );
      return filteredProposars;
    } else {
      return state;
    }
  },
  [createProposar.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [setProposar]: (state, action) => action.payload,
  [getProposarById.fulfilled]: (state, action) => action.payload,
  [updateProposar.fulfilled]: (state, action) => {
    const updatedProposar = action.payload;
    const index = state.findIndex(
      (proposar) => proposar._id === updatedProposar._id
    );
    if (index !== -1) {
      state[index] = updatedProposar;
    }
  },
});

export default proposarReducer;
