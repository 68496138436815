import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Stack,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Input,
  FormControl,
  Checkbox,
  Button,
  Box,
  useDisclosure,
  Text,
  FormErrorMessage,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { createAdmin } from "../../../store/reducers/admin.reducer";
import { showToast } from "../../../commons/SweetAlertToast";

const CreateAdmin = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [superAdmin, setSuperAdmin] = useState(false);
  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phone: "",
    address: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const submit = async () => {
    for (const key in formData) {
      if (formData[key] === "") {
        setError(`Please complete all fields`);
        return;
      }
    }

    const resp = await dispatch(
      createAdmin({
        ...formData,
        role: "admin",
        sendEmail: true,
        superAdmin,
      })
    );
    if (resp.payload) {
      setError("");
      setFormData({
        email: "",
        name: "",
        phone: "",
        address: "",
      });
      onClose();
      showToast("Admin created", "success");
    } else {
      showToast("Error", "error");
    }
  };

  return (
    <>
      <Center mt={10}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          bg="rgb(255,134,0)"
          _hover={{ bg: "rgba(255,134,0,0.5)" }}
          onClick={onOpen}
          size="xs"
        >
          Add
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size={"7xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            textAlign={"center"}
            color={"nevtis.1"}
            fontSize={"2xl"}
            fontWeight={"bold"}
          >
            Create a new Admin
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ width: "100%" }}>
              <Stack spacing={2} width={"full"}>
                <Box mb={10}>
                  <Checkbox
                    colorScheme="orange"
                    ml={5}
                    isChecked={superAdmin}
                    onChange={(e) => setSuperAdmin(e.target.checked)}
                  >
                    Super Admin?
                  </Checkbox>
                </Box>
                <Stack direction="row" spacing={4}>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Name</FormLabel>
                    <Input
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Address</FormLabel>
                    <Input
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={4}>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Phone Number</FormLabel>
                    <Input
                      name="phone"
                      type="number"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Email address</FormLabel>
                    <Input
                      name="email"
                      value={formData.email}
                      type="email"
                      onChange={handleInputChange}
                    />
                    <FormErrorMessage />
                  </FormControl>
                </Stack>
              </Stack>
            </Box>

            <Text color="red">{error}</Text>
            <Center mt={10} mb={4}>
              <Button
                onClick={submit}
                type="submit"
                bg={"nevtis.1"}
                _hover={{ bg: "nevtis.1.4" }}
                color={"white"}
              >
                Submit
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const formLabelStyle = {
  color: "rgba(255,134,0)",
  fontWeight: "bold",
};

export default CreateAdmin;
