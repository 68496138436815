import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Pagination from "../../../commons/general/Pagination";
import CreatePromotions from "./CreatePromotions";
import { showToast } from "../../../commons/SweetAlertToast";

const TablePromotions = () => {
  const [promotions, setPromotions] = useState([]);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedDiscount, setEditedDiscount] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [isDesktop] = useMediaQuery("(min-width: 800px)");

  useEffect(() => {
    axios
      .get("https://api.nevtis.com/marketplace/promotions/all")
      .then((res) => {
        setPromotions(res.data);
      })
      .catch((error) => {
        console.error("Error fetching promotions:", error);
      });
  }, []);

  const handleDeletePromotions = async () => {
    try {
      await axios.delete(
        `https://api.nevtis.com/marketplace/promotions/delete/${selectedPromotion._id}`
      );
      setPromotions((prevCategories) =>
        prevCategories.filter((cat) => cat._id !== selectedPromotion._id)
      );
      showToast("Promotion Deleted", "success");
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting Promotion:", error);
    }
  };

  const handleEditPromotions = async () => {
    try {
      const res = await axios.put(
        `https://api.nevtis.com/marketplace/promotions/update/${selectedPromotion._id}`,
        {
          name: editedName,
          discount: editedDiscount,
        }
      );
      showToast("Promotion Updated", "success");
      setPromotions((prevPromotions) =>
        prevPromotions.map((prom) =>
          prom._id === selectedPromotion._id
            ? {
                ...prom,
                name: res.data.promotion.name,
                discount: res.data.promotion.discount,
              }
            : prom
        )
      );
      setIsEditing(false);
      setSelectedPromotion(null);
      setEditedName("");
      setEditedDiscount("");
    } catch (error) {
      console.error("Error editing Promotion:", error);
    }
  };

  const handleOpenEditModal = (promotion) => {
    setSelectedPromotion(promotion);
    setEditedName(promotion.name);
    setEditedDiscount(promotion.discount);
    setIsEditing(true);
  };

  const handleCloseEditModal = () => {
    setIsEditing(false);
    setSelectedPromotion(null);
    setEditedName("");
    setEditedDiscount("");
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleInputPerPage = (value) => {
    setItemsPerPage(Number(value));
  };

  const filteredData = promotions.filter((item) =>
    item.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const sections = filteredData.reduce(
    (acc, item, index) =>
      index % itemsPerPage === 0
        ? [...acc, filteredData.slice(index, index + itemsPerPage)]
        : acc,
    []
  );

  const currentSection = sections[currentPage - 1];

  const path = useLocation();

  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const utcYear = date.getUTCFullYear();
    const utcMonth = date.getUTCMonth() + 1;
    const utcDay = date.getUTCDate();
    const formattedDate = `${utcMonth.toString().padStart(2, "0")}-${utcDay
      .toString()
      .padStart(2, "0")}-${utcYear}`;
    return formattedDate;
  }

  return (
    <Box mt={4} mx={10}>
      <Stack direction={{ base: "column", md: "row" }} spacing={10} mb={4}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by Promotion Name"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <Flex justifyContent="flex-end" alignItems="center">
          <Text color={"nevtis.1"} mr={2}>
            Items per Page
          </Text>
          <NumberInput
            size="xs"
            maxW={16}
            defaultValue={itemsPerPage}
            min={1}
            onChange={handleInputPerPage}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Stack>

      <CreatePromotions setPromotions={setPromotions} />

      <TableContainer
        mt={4}
        maxHeight={path.pathname === "/client" ? "320px" : "800px"}
        overflowY="scroll"
      >
        <Table variant="simple" size={isDesktop ? "2xl" : "xs"}>
          <Thead
            position={"sticky"}
            zIndex={1}
            top={0}
            bg={"white"}
            fontSize={!isDesktop && "xs"}
            textAlign={!isDesktop && "center"}
          >
            <Tr>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Name
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Discount
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Valid Start / Valid End
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentSection &&
              currentSection.map((item) => (
                <Tr key={item._id}>
                  <Td textAlign="center">{item.name}</Td>
                  <Td textAlign="center">{item.discount}</Td>
                  <Td textAlign="center">
                    {formatDate(item.validStart)} / {formatDate(item.validEnd)}
                  </Td>
                  <Td textAlign="center">
                    <Button
                      colorScheme="blue"
                      size="xs"
                      mr={2}
                      onClick={() => handleOpenEditModal(item)}
                    >
                      Edit
                    </Button>
                    <Button
                      colorScheme="red"
                      size="xs"
                      onClick={() => {
                        setSelectedPromotion(item);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        sections={sections}
        currentPage={currentPage}
        handlePageChange={setCurrentPage}
      />

      <Modal isOpen={isEditing} onClose={handleCloseEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Promotion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Input
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                placeholder="Promotion Name"
              />
              <Input
                value={editedDiscount}
                onChange={(e) => setEditedDiscount(e.target.value)}
                placeholder="Promotion Discount"
              />
            </Stack>
            <Button mt={4} colorScheme="blue" onClick={handleEditPromotions}>
              Save Changes
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Promotion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this promotion?</Text>
            <Button mt={4} colorScheme="red" onClick={handleDeletePromotions}>
              Confirm Delete
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TablePromotions;
