import axios from "axios";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { URL_USER } from "../../config/apiBaseURL";

const initialState = [];

export const getAllSeller = createAsyncThunk("GET_SELLER", () => {
  return axios.get(`${URL_USER}/users/seller/all`).then((r) => r.data);
});

export const getSellerById = createAsyncThunk("GET_SELLER", (sellerId) => {
  return axios
    .get(`${URL_USER}/users/user/${sellerId}`)
    .then((r) => r.data);
});

export const deleteSeller = createAsyncThunk(
  "DELETE_SELLER",
  async (sellerId) => {
    const response = await axios.delete(
      `${URL_USER}/users/seller/delete/${sellerId}`
    );
    return response.data;
  }
);
export const updateSeller = createAsyncThunk("UPDATE_SELLER", async (data) => {
  const response = await axios.put(`${URL_USER}/users/seller/update`, data);
  return response.data;
});

export const createSeller = createAsyncThunk("CREATE_SELLER", async (data) => {
  const response = await axios.post(
    `${URL_USER}/users/seller/create`,
    data
  );
  return response.data;
});

export const setSeller = createAction("SET_SELLER");

const sellerReducer = createReducer(initialState, {
  [getAllSeller.fulfilled]: (state, action) => action.payload,
  [deleteSeller.fulfilled]: (state, action) => {
    if (action.payload) {
      const filteredUsers = state.filter(
        (user) => user._id !== action.payload._id
      );
      return filteredUsers;
    } else {
      return state;
    }
  },
  [createSeller.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [setSeller]: (state, action) => action.payload,
  [getSellerById.fulfilled]: (state, action) => action.payload,
  [updateSeller.fulfilled]: (state, action) => {
    const updateSeller = action.payload;
    const index = state.findIndex((seller) => seller._id === updateSeller._id);
    if (index !== -1) {
      state[index] = updateSeller;
    }
  },
});

export default sellerReducer;
