import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Flex,
} from "@chakra-ui/react";
import { IoIosNotifications } from "react-icons/io";
import { BsChatFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import axios from "axios";
import { URL_USER } from "../../config/apiBaseURL";
import { Link } from "react-router-dom";

const NotificationsList = () => {
  const notifications = useSelector((state) => state.chats.notifications);
  const [uniqueNotifications, setUniqueNotifications] = useState([]);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  useEffect(() => {
    const fetchUniqueNotifications = async () => {
      const uniqueUsers = [];

      for (const notification of notifications) {
        try {
          const response = await axios.get(
            `${URL_USER}/users/user/${notification.senderId}`
          );
          const user = response.data;

          // Verificar si el usuario ya está en la lista de notificaciones únicas
          if (!uniqueUsers.some((u) => u._id === user._id)) {
            uniqueUsers.push(user);
          }
        } catch (error) {
          console.error("Error fetching recipient user:", error);
        }
      }

      setUniqueNotifications(uniqueUsers);
      // Verificar si hay notificaciones nuevas
      setHasNewNotifications(uniqueUsers.length > 0);
    };

    fetchUniqueNotifications();
  }, [notifications]);

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={
          <Box position="relative">
            <IoIosNotifications
              size={"35px"}
              color={hasNewNotifications ? "rgba(255,134,0)" : "gray"}
              animate={hasNewNotifications ? { opacity: 1 } : {}}
            />
            {uniqueNotifications.length > 0 && (
              <Box
                position="absolute"
                top="-8px"
                right="-8px"
                bg="red"
                borderRadius="50%"
                width="20px"
                height="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontSize="12px"
                fontWeight="bold"
                color="white"
              >
                {notifications.length}
              </Box>
            )}
          </Box>
        }
        bg={"transparent"}
      />
      <MenuList maxHeight="200px" overflowY="auto">
        {uniqueNotifications.length === 0 ? (
          <MenuItem>
            <Text>No notifications</Text>
          </MenuItem>
        ) : (
          uniqueNotifications.slice(0, 4).map((user, index) => (
            <MenuItem key={index} minH="48px">
              <Link to={"/chat"}>
                <Flex>
                  <BsChatFill color="rgba(255,134,0)" mr="4px" />

                  <Text ml={2}>Message from {user?.name || "Unknown user"}</Text>
                </Flex>
              </Link>
            </MenuItem>
          ))
        )}
      </MenuList>
    </Menu>
  );
};

export default NotificationsList;
