import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Center,
  Image,
  FormHelperText,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { forgotPassRequest } from "../../store/reducers/user.reducer";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import logo from "../../assets/logo1.png";
import isotipo from "../../assets/isotipo.png";
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPassApp = () => {
  const { handleSubmit } = useForm();
  const [email, setEmail] = useState("");
  const [isEmailEmpty, setIsEmailEmpty] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const captcha = useRef(null);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-start",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const forgotPass = async (e) => {
    e.preventDefault();

    if (isEmailEmpty) {
      showErrorToast("Email field is required");
      return;
    }

    const recaptchaToken = await new Promise((resolve) => {
      const token = captcha.current.getValue();
      resolve(token);
    });

    if (!recaptchaToken) {
      showErrorToast("Please complete the reCAPTCHA challenge.");
      return;
    }

    try {
      const resp = await dispatch(forgotPassRequest(email));
      if (resp) {
        showQuestionToast(resp.payload);
        navigate("/app");
      }
    } catch (error) {
      showErrorToast("An error occurred. Please try again later.");
    }
  };

  const showErrorToast = (message) => {
    Toast.fire({
      icon: "error",
      title: message,
    });
  };

  const showQuestionToast = (message) => {
    Toast.fire({
      icon: "question",
      title: message,
    });
  };

  return (
    <Box maxW="400px" m="auto" p={4}>
      <Center mb={20} mt={40}>
        <Box maxWidth={"200px"}>
          <Image src={logo} />
        </Box>
      </Center>
      <form onSubmit={handleSubmit}>
        <FormControl isRequired mt={40}>
          <FormLabel color={"nevtis.1"} fontSize={"lg"}>
            Email
          </FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setIsEmailEmpty(e.target.value === "");
            }}
          />
          <FormHelperText>
            Password recovery process to help you regain access to your account.
            By submitting your email, you will be guided through the necessary
            steps to reset your password securely.
          </FormHelperText>
        </FormControl>
        <FormControl zIndex={1}> 
          <ReCAPTCHA
            sitekey="6Ld1OeQmAAAAANI1clqIrRwdg8K3Y5Q2VzTPtbDv"
            ref={captcha}
          />
        </FormControl>
        <Center mt={20}>
          <Button
            zIndex={1}
            onClick={forgotPass}
            size="md"
            w="full"
            type="submit"
            bg={"rgba(255,134,0)"}
            color={"gray.100"}
            _hover={{
              bg: "rgba(255,134,0,0.5)",
            }}
          >
            Submit
          </Button>
        </Center>
        <Center mt={2}>
          <Button
            zIndex={1}
            onClick={() => navigate("/app")}
            size="md"
            w="full"
            type="submit"
            colorScheme="blue"
            color={"gray.100"}
          >
            Back to Login
          </Button>
        </Center>
      </form>

      <Box>
        <Image src={isotipo} opacity={0.1} position={"fixed"} top={"470px"} />
      </Box>
    </Box>
  );
};

export default ForgotPassApp;
