import React, { useState } from "react";
import {
  Stack,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Input,
  FormControl,
  Button,
  Box,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import axios from "axios";
import { showToast } from "../../../commons/SweetAlertToast";

const CreateCategories = ({ setCategories }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const submit = async () => {
    // Validación de campos
    for (const key in formData) {
      if (formData[key] === "" && key !== "description") {
        setError(`Please complete all fields`);
        return;
      }
    }

    try {
      const response = await axios.post(
        "https://api.nevtis.com/marketplace/categories/create",
        formData
      );
      setCategories((prevCategories) => [...prevCategories, response.data]);
      showToast("Category created", "success");
      setFormData({
        name: "",
        description: "",
      });
      onClose();
    } catch (error) {
      console.error("Error creating category:", error);
      setError("Failed to create category");
    }
  };

  return (
    <>
      <Center mt={4}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          bg="rgb(255,134,0)"
          _hover={{ bg: "rgba(255,134,0,0.5)" }}
          onClick={onOpen}
          size="xs"
        >
          Add Category
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            textAlign={"center"}
            color={"nevtis.1"}
            fontSize={"2xl"}
            fontWeight={"bold"}
          >
            Create a new Category
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ width: "100%" }}>
              <Stack spacing={2} width={"full"}>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Category Name</FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel style={formLabelStyle}>Description</FormLabel>
                  <Input
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Stack>
            </Box>

            <Text color="red">{error}</Text>
            <Center mt={10} mb={4}>
              <Button
                onClick={submit}
                type="submit"
                bg={"nevtis.1"}
                _hover={{ bg: "nevtis.1.4" }}
                color={"white"}
              >
                Submit
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const formLabelStyle = {
  color: "rgba(255,134,0)",
  fontWeight: "bold",
};

export default CreateCategories;
