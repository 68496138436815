import React, { useState, useEffect } from "react";
import {
  Stack,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Input,
  FormControl,
  Select,
  NumberInput,
  NumberInputField,
  Button,
  Box,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import axios from "axios";
import { showToast } from "../../../commons/SweetAlertToast";

const CreateProduct = ({ setProducts }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    images: [],
    inStock: 0,
    price: 0,
    slug: "",
    type: "",
    category: "",
  });
  const [error, setError] = useState("");
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.nevtis.com/marketplace/categories/all")
      .then((res) => {
        setCategories(res.data);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: e.target.files[0],
    }));
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      if (formData.images.name) {
        const formDataToSubmit = new FormData();
        formDataToSubmit.append("file", formData.images);
        const uploadResponse = await axios.post(
          "https://adminspace.nevtis.com/uploadFile",
          formDataToSubmit,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (uploadResponse.data) {
          const productData = {
            ...formData,
            images: [uploadResponse.data.key],
          };

          const createProductResponse = await axios.post(
            "https://api.nevtis.com/marketplace/products/create",
            productData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (createProductResponse.data) {
            setProducts((prevProd) => [
              ...prevProd,
              createProductResponse.data,
            ]);
            showToast("Product created", "success");
            setError(""); // Restablecer error después de enviar con éxito
            onClose(); // Cierra el modal después de enviar con éxito
            setIsLoading(false);
          }
        }
      } else {
        const createProductResponse = await axios.post(
          "https://api.nevtis.com/marketplace/products/create",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (createProductResponse.data) {
          setProducts((prevProd) => [...prevProd, createProductResponse.data]);
          showToast("Product created", "success");
          setError(""); // Restablecer error después de enviar con éxito
          onClose(); // Cierra el modal después de enviar con éxito
          setIsLoading(false);
        }
      }
    } catch (error) {
      setError("Error al enviar el formulario. Inténtalo de nuevo.");
      console.error(error);
    }
  };

  return (
    <>
      <Center mt={4}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          bg="rgb(255,134,0)"
          _hover={{ bg: "rgba(255,134,0,0.5)" }}
          onClick={onOpen}
          size="xs"
        >
          Add Product
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            textAlign={"center"}
            fontSize={"2xl"}
            fontWeight={"bold"}
          >
            Create a new Product
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ width: "100%" }}>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Title</FormLabel>
                  <Input
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Description</FormLabel>
                  <Input
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    as="textarea"
                    minHeight="150px"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Images</FormLabel>
                  <Input
                    name="images"
                    type="file"
                    onChange={handleImageChange}
                    multiple
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Slug</FormLabel>
                  <Input
                    name="slug"
                    value={formData.slug}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Type</FormLabel>
                  <Input
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Category</FormLabel>
                  <Select
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                  >
                    {categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>In Stock</FormLabel>
                  <NumberInput
                    value={formData.inStock}
                    onChange={(value) =>
                      setFormData((prev) => ({ ...prev, inStock: value }))
                    }
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Price</FormLabel>
                  <NumberInput
                    value={formData.price}
                    onChange={(value) =>
                      setFormData((prev) => ({ ...prev, price: value }))
                    }
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              </Stack>
            </Box>

            <Text color="red">{error}</Text>
            <Center mt={6}>
              <Button
                onClick={submit}
                isLoading={isLoading}
                loadingText="Loading"
                colorScheme="teal"
                variant="outline"
                spinnerPlacement="start"
              >
                Submit
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateProduct;
