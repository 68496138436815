import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  useDisclosure,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Image,
  Flex,
  Center,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { showToast } from "../../../commons/SweetAlertToast";
const EditProduct = ({ product, setProducts }) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [employeeData, setEmployeeData] = useState([product]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onChange = (e, _id) => {
    const { name, value, type, checked } = e.target;
    const updatedEmployeeData = employeeData.map((item) =>
      item._id === _id
        ? { ...item, [name]: type === "checkbox" ? checked : value }
        : item
    );
    setEmployeeData(updatedEmployeeData);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `https://api.nevtis.com/marketplace/products/update/${product._id}`,
        {
          data,
        }
      );
      setProducts((prevPr) =>
        prevPr.map((prod) =>
          prod._id === product._id
            ? {
                ...prod,
                description: res.data.description,
                inStock: res.data.inStock,
                price: res.data.price,
                short: res.data.short,
                title: res.data.title,
                slug: res.data.type,
                type: res.data.description,
              }
            : prod
        )
      );
      showToast("Product Updated", "success");
      onClose();
      setLoading(false);
    } catch (error) {
      console.error("Error editing category:", error);
      // Handle error
    }
  };

  return (
    <>
      {/*     <MenuItem onClick={onOpen}>Edit</MenuItem> */}
      <Button onClick={onOpen} colorScheme={"blue"} size="xs">
        Edit
      </Button>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <Center mt={2}>
            <Image
              src={`https://api.nevtis.com/marketplace/files/list/${product.images[0]}`}
              height="102px"
              p={2}
            />
          </Center>
          <DrawerHeader borderBottomWidth="1px " textAlign={"center"}>
            {product.title}
          </DrawerHeader>
          <Flex>
            <Center></Center>
          </Flex>
          <DrawerBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <>
                {employeeData.map(
                  ({
                    _id,
                    description,
                    inStock,
                    price,
                    short,
                    title,
                    slug,
                    type,
                  }) => (
                    <Stack spacing={2} key={_id}>
                      <FormControl>
                        <FormLabel>title</FormLabel>
                        <Input
                          {...register("title")}
                          value={title}
                          onChange={(e) => onChange(e, _id)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>description</FormLabel>
                        <Input
                          {...register("description")}
                          value={description}
                          onChange={(e) => onChange(e, _id)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>inStock</FormLabel>
                        <Input
                          {...register("inStock")}
                          value={inStock}
                          onChange={(e) => onChange(e, _id)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>price Number</FormLabel>
                        <Input
                          {...register("price")}
                          value={price}
                          onChange={(e) => onChange(e, _id)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>short Number</FormLabel>
                        <Input
                          {...register("short")}
                          value={short}
                          onChange={(e) => onChange(e, _id)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>slug Number</FormLabel>
                        <Input
                          {...register("slug")}
                          value={slug}
                          onChange={(e) => onChange(e, _id)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>type Number</FormLabel>
                        <Input
                          {...register("type")}
                          value={type}
                          onChange={(e) => onChange(e, _id)}
                        />
                      </FormControl>

                      <Stack spacing={10}>
                        <Stack
                          direction={{ base: "column", sm: "row" }}
                          align={"start"}
                          justify={"space-between"}
                        ></Stack>
                        <Button
                          isLoading={loading}
                          type="submit"
                          bg={"rgba(255,134,0)"}
                          color={"black"}
                          _hover={{
                            bg: "rgba(255,134,0,0.5)",
                          }}
                        >
                          Edit
                        </Button>
                      </Stack>
                    </Stack>
                  )
                )}
              </>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default EditProduct;
