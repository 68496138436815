import React, { useRef, useLayoutEffect, useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  InputGroup,
  Button,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { BsSend } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { createMessage } from "../../store/reducers/chat.reducer";
import { MdDeleteOutline } from "react-icons/md";
import Swal from "sweetalert2";
import InputEmoji from "react-input-emoji";
import { useFetchRecipient } from "../../hooks/useFetchRecipient";

const ConversationArea = () => {
  const dispatch = useDispatch();
  const messageContainerRef = useRef(null);
  const [textMessages, setTextMessages] = useState(" ");
  const user = useSelector((state) => state.user);
  const chat = useSelector((state) => state.chats.currentChat);
  const { recipientUser } = useFetchRecipient(chat, user);
  const messages = useSelector((state) => state.messages);

  const onSubmit = async () => {
    if (!textMessages.trim()) {
      return;
    }
    await dispatch(
      createMessage({
        text: textMessages,
        chatId: chat._id,
        senderId: user._id,
        recipientId: recipientUser._id,
      })
    ).then(() => {
      setTextMessages("");
    });
  };

  const handleDelete = async (data) => {
    Swal.fire({
      title: `Delete Message?`,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        /*         dispatch(deleteClient(user._id)).then((resp) => {
          if (resp) {
            Swal.fire("Deleted!", "", "success");
            navigate("/client");
          }
        }); */
      }
    });
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MM-dd-yyyy hh:mm");
  };

  useLayoutEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const [hoveredMessageId, setHoveredMessageId] = useState(null);

  return (
    <Box width={"900px"}>
      {recipientUser && (
        <>
          <Box
            px={5}
            overflow={"auto"}
            mt={5}
            pb={5}
            height={"380px"}
            ref={messageContainerRef}
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "rgba(255, 134, 0, 0.1)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(255, 134, 0, 0.5)",
                borderRadius: "4px",
              },
            }}
          >
            {messages.length === 0 ? (
              <Text textAlign={"center"} mt={20}>
                Not messages yet.
              </Text>
            ) : (
              messages.map((message, index) => (
                <Flex
                  mt={1}
                  key={message._id}
                  justify={
                    message.senderId === user._id ? "flex-end" : "flex-start"
                  }
                  onMouseEnter={() => setHoveredMessageId(message._id)}
                  onMouseLeave={() => setHoveredMessageId(null)}
                  position="relative"
                >
                  <Box
                    textAlign={message.senderId === user._id ? "right" : "left"}
                  >
                    {(index === 0 ||
                      message.senderId !== messages[index - 1].senderId) && (
                      <Flex justifyContent="flex-end">
                        <Text fontSize="xs">
                          {message.senderId === user._id
                            ? "You "
                            : recipientUser.companyName ||
                              recipientUser.name}{" "}
                          {formattedDate(message.createdAt)}
                        </Text>
                      </Flex>
                    )}
                    <Box
                      bg={
                        message.senderId === user._id
                          ? "nevtis.1.5"
                          : "gray.300"
                      }
                      py={2}
                      px={4}
                      borderRadius={"md"}
                      width={"auto"}
                      textAlign={"initial"}
                    >
                      <Text fontSize="md">{message.text}</Text>
                      {message.senderId === user._id &&
                        hoveredMessageId === message._id && (
                          <IconButton
                            icon={<MdDeleteOutline color="red" size={"15px"} />}
                            size={"xs"}
                            ml={4}
                            position="absolute"
                            _hover={{ bg: "red.300" }}
                            bg={"red.100"}
                            transform="translateY(-50%)"
                            right={1}
                            onClick={() => handleDelete(message)}
                          />
                        )}
                    </Box>
                  </Box>
                </Flex>
              ))
            )}
          </Box>
          <Box mt={10}>
            <form>
              <FormControl
                border="solid 1px lightgray"
                borderColor={"rgba(255,134,0,0.3)"}
                p={2}
                borderRadius="md"
              >
                <InputGroup>
                  <InputEmoji
                    value={textMessages}
                    onChange={setTextMessages}
                    fontFamily="nunito"
                    onEnter={onSubmit}
                  />
                  <Button
                    type="submit"
                    colorScheme="orange"
                    size="sm"
                    ml={1}
                    mt={2}
                  >
                    <BsSend />
                  </Button>
                </InputGroup>
              </FormControl>
            </form>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ConversationArea;
