import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Stack,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Input,
  FormControl,
  Button,
  Box,
  useDisclosure,
  Select,
  Text,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { createSoftphoneUser } from "../../../store/reducers/softphoneUser.reducer";
import { showToast } from "../../../commons/SweetAlertToast";

const CreateSoftphoneUser = ({
  customer,
  extensionsByCustomer,
  softphoneUsers,
}) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState("");

  const extensionsNotInSoftphoneUsers = extensionsByCustomer.filter(
    (extension) => {
      return !softphoneUsers.some(
        (user) => user.extension.extension === extension.extension
      );
    }
  );

  const [formData, setFormData] = useState({
    name: "",
    address: customer.address,
    phone: customer.phone,
    email: "",
    extension: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const submit = async () => {
    for (const key in formData) {
      if (formData[key] === "") {
        setError(`Please complete all fields`);
        return;
      }
    }

    let extension = JSON.parse(formData.extension);

    const resp = await dispatch(
      createSoftphoneUser({
        ...formData,
        extension,
        role: "softphoneUser",
        company: customer._id,
        sendEmail: true,
      })
    );
    if (resp) {
      setFormData({
        name: "",
        address: "",
        phone: "",
        email: "",
        extension: "",
      });
      onClose();
      showToast("Softphone created", "success");
    } else {
      showToast("Error", "error");
    }
  };

  return (
    <>
      <Center mt={1}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          bg="rgb(255,134,0)"
          _hover={{ bg: "rgba(255,134,0,0.5)" }}
          onClick={onOpen}
          size="xs"
        >
          Add
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size={"7xl"}>
        <ModalOverlay />
        <ModalContent px={20} py={10}>
          <ModalHeader
            textAlign={"center"}
            color={"nevtis.1"}
            fontSize={"2xl"}
            fontWeight={"bold"}
            mb={10}
          >
            Create a new Softphone User
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ width: "100%" }}>
              <Stack spacing={2} width={"full"}>
                <Stack direction="row" spacing={4}>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Name</FormLabel>
                    <Input
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Address</FormLabel>
                    <Input
                      isDisabled
                      name="address"
                      value={customer.address}
                      onChange={handleInputChange}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Phone Number</FormLabel>
                    <Input
                      isDisabled
                      name="phone"
                      value={customer.phone}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={4}>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Email address</FormLabel>
                    <Input
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Extension</FormLabel>

                    <Select
                      name="extension"
                      onChange={handleInputChange}
                      value={formData.extension}
                    >
                      <option value="">Seleccione</option>{" "}
                      {extensionsNotInSoftphoneUsers.map((item, key) => (
                        <option value={JSON.stringify(item)} key={key}>
                          {item.extension} - {item.effective_caller_id_name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
              <Text color="red">{error}</Text>
            </Box>
            <Center mt={10}>
              <Button
                onClick={submit}
                color={"white"}
                bg={"nevtis.1"}
                _hover={{ bg: "nevtis.1.4" }}
              >
                Create
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const formLabelStyle = {
  color: "rgba(255,134,0)",
  fontWeight: "bold",
};

export default CreateSoftphoneUser;
