import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure,
  ButtonGroup,
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import { showToast } from "../../../commons/SweetAlertToast";

const DeleteProduct = ({ product, setProducts }) => {
  const { isOpen, onClose } = useDisclosure();

  const handleDeleteProduct = async () => {
    Swal.fire({
      title: `Delete ${product.title}?`,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios.delete(
            `https://api.nevtis.com/marketplace/products/delete/${product._id}`
          );
          setProducts((prevProduct) =>
            prevProduct.filter((prod) => prod._id !== product._id)
          );
          showToast("Product Deleted", "success");
        } catch (error) {
          console.error("Error deleting product:", error);
        }
      }
    });
  };

  return (
    <>
      {" "}
      <Button onClick={handleDeleteProduct} colorScheme={"red"} size="xs">
        Delete
      </Button>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <h1 aria-hidden="true"></h1>
        </PopoverTrigger>

        <PopoverContent>
          <PopoverHeader fontWeight="semibold">
            Delete {product.name}?
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>Are you sure?</PopoverBody>
          <PopoverFooter display="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteProduct}>
                Delete
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default DeleteProduct;
