import React from "react";
import { Box, Button } from "@chakra-ui/react";
import Calendar from "react-calendar";

const DateRangeSelector = ({
  selectedDate,
  handleDateSelect,
  isOpen,
  setOpenCalendar,
}) => {
  return (
    <Box position="relative">
      <Button
        color="nevtis.1"
        borderColor="rgba(255,134,0)"
        bg="white"
        _hover={{ bg: "rgba(255,134,0)", color: "black" }}
        borderWidth={1}
        onClick={() => setOpenCalendar(!isOpen)}
        mr={2}
      >
        {selectedDate}
      </Button>
      {isOpen && (
        <Box zIndex={2} position="absolute">
          <Calendar
            onChange={handleDateSelect}
            value={selectedDate}
            className="calendar"
          />
        </Box>
      )}
    </Box>
  );
};

export default DateRangeSelector;
