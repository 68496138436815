import React, { useState } from "react";
import { Box, Text, Heading } from "@chakra-ui/react";
import axios from "axios";
import { showToast } from "../../commons/SweetAlertToast";

const FileUploader = () => {
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      const response = await axios.post(
        "https://adminspace.nevtis.com/uploadFile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      showToast(`${response.data.key} added`, "success");
    } catch (error) {
      showToast(`error`, "error");
      console.error("Error al cargar el archivo:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={4}>
      <Heading color="nevtis.1" mb={10} size={"md"}>
        Upload Nevtis App (Mac & Windows)
      </Heading>
      {loading ? (
        <Box mt={4}>
          <Box className="loader">
            <Text textAlign="center">Loading, Please Wait</Text>
          </Box>
        </Box>
      ) : (
        <input type="file" onChange={handleFileUpload} />
      )}
    </Box>
  );
};

export default FileUploader;
