// ChartComponent.js
import React from "react";
import {
  ResponsiveContainer,
  Tooltip,
  BarChart,
  XAxis,
  Bar,
  Cell,
} from "recharts";
import { Box, Text } from "@chakra-ui/react";
import { FaRegWindowClose } from "react-icons/fa";

const COLORS = [
  "#36a2eb",
  "#ffce56",
  "#ff6384",
  "#4bc0c0",
  "#9966cc",
  "#ff3752",
];

const ChartComponent = ({ fullscreen, chartData, totalCalls }) => {
  return (
    <>
      {totalCalls > 0 ? (
        <Box>
          <ResponsiveContainer width="100%" height={fullscreen ? 500 : 300}>
            <BarChart data={chartData}>
              <Tooltip cursor={false} />
              <XAxis
                dataKey="name"
                tick={{
                  fontSize: fullscreen ? 20 : 14,
                  fill: "gray.200",
                }}
              />

              <Bar
                dataKey="value"
                nameKey="name"
                fill="#8884d8"
                barSize={fullscreen ? 120 : 80}
                label={({ x, y, width, value }) => {
                  return (
                    <>
                      {value > 0 && (
                        <text
                          x={x + width / 2}
                          y={y + 20}
                          style={{ fontWeight: "bold", color: "black" }}
                          textAnchor="middle"
                          dy={-6}
                        >{`${value}`}</text>
                      )}
                    </>
                  );
                }}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    style={{
                      filter: `drop-shadow(3px 3px 5px ${
                        COLORS[index % COLORS.length]
                      })`,
                    }}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "37vh",
          }}
        >
          <FaRegWindowClose fontSize="60px" color="rgba(255,134,0)" />
          <Text
            textAlign="center"
            color="nevtis.1"
            fontWeight="bold"
            fontSize="xl"
            ml={5}
          >
            No Calls
          </Text>
        </Box>
      )}
    </>
  );
};

export default ChartComponent;
