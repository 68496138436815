import React, { useState } from "react";
import {
  Badge,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Link as LinkChakra,
  Tr,
  useMediaQuery,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
  Stack,
  TableContainer,
  Center,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import CreateCustomer from "./CreateCustomer";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../../../commons/general/Pagination";

const TableUsers = ({ data }) => {
  const user = useSelector((state) => state.user);
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [ItemsPerPage, setItemsPerPage] = useState(100);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const filteredDataSw = data;
  const filteredData = filteredDataSw.filter(
    (item) =>
      item.companyName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.email?.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleInputPerPage = (value) => {
    setItemsPerPage(Number(value));
  };

  const sections = filteredData.reduce(
    (acc, item, index) =>
      index % ItemsPerPage === 0
        ? [...acc, filteredData.slice(index, index + ItemsPerPage)]
        : acc,
    []
  );

  const currentSection = sections[currentPage - 1];

  const handleSearchChange = (e) => setSearchValue(e.target.value);

  return (
    <Box mt={isDesktop ? 4 : 0} ml={isDesktop && 5}>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={10}
        mb={isDesktop ? 4 : 0}
      >
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by email or name"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <Flex justifyContent="flex-end" alignItems="center">
          <Text color={"nevtis.1"} mr={2}>
            Items per Page
          </Text>
          <NumberInput
            size="xs"
            maxW={16}
            defaultValue={ItemsPerPage}
            min={1}
            onChange={handleInputPerPage}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Stack>

      <CreateCustomer />
      <TableContainer
        mt={4}
        maxHeight={isDesktop ? "500px" : "250px"}
        overflowY="scroll"
      >
        <Table variant="simple" size={isDesktop ? "2xl" : "xs"}>
          <Thead
            position={"sticky"}
            zIndex={1}
            top={0}
            bg={"white"}
            fontSize={!isDesktop && "xs"}
            textAlign={!isDesktop && "center"}
          >
            <Tr>
              {isDesktop ? (
                <>
                  <Th
                    color={"rgba(255,134,0)"}
                    height="50px"
                    textAlign={"center"}
                    fontSize={"sm"}
                  >
                    Name
                  </Th>
                  <Th
                    color={"rgba(255,134,0)"}
                    height="50px"
                    textAlign={"center"}
                    fontSize={"sm"}
                  >
                    Email
                  </Th>
                  <Th
                    color={"rgba(255,134,0)"}
                    height="50px"
                    textAlign={"center"}
                    fontSize={"sm"}
                  >
                    Phone
                  </Th>
                  <Th
                    color={"rgba(255,134,0)"}
                    height="50px"
                    textAlign={"center"}
                    fontSize={"sm"}
                  >
                    <p>Server URL</p>
                  </Th>
                  {(data.length === 0 || !data[0].leads) && (
                    <Th
                      color={"rgba(255,134,0)"}
                      height="50px"
                      textAlign={"center"}
                      fontSize={"sm"}
                    >
                      Status
                    </Th>
                  )}
                </>
              ) : (
                <>
                  <Th
                    color={"rgba(255,134,0)"}
                    height="50px"
                    textAlign={!isDesktop && "center"}
                  >
                    Company Name
                  </Th>

                  <Th
                    color={"rgba(255,134,0)"}
                    height="50px"
                    textAlign={!isDesktop && "center"}
                  >
                    Status
                  </Th>
                </>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {currentSection &&
              currentSection
                .filter((userP) => userP._id !== user._id)
                .map((item) => (
                  <Tr key={item._id}>
                    {isDesktop ? (
                      <>
                        <Td textAlign={"center"}>
                          <Link to={`/client/${item._id}`}>
                            <Text color={"nevtis.1"} as={"u"}>
                              {item.companyName}
                            </Text>
                          </Link>
                        </Td>

                        <Td textAlign={"center"}>
                          {!item.email && "-"}
                          {item.email?.length > 20
                            ? item.email.slice(0, 20) + "..."
                            : item.email}
                        </Td>

                        <Td textAlign={"center"}>{item.phone}</Td>

                        <Td>
                          <Center fontSize={"sm"}>
                            <LinkChakra
                              href={item.serverURL}
                              isExternal
                              color={"nevtis.1"}
                              fontSize={"md"}
                            >
                              {item.serverURL}
                            </LinkChakra>
                          </Center>
                        </Td>

                        <Td>
                          {item.isActivated ? (
                            <Center>
                              <Badge
                                backgroundColor={"rgba(255,134,0)"}
                                color="gray.50"
                                p={1}
                                borderRadius={5}
                              >
                                Active
                              </Badge>
                            </Center>
                          ) : (
                            <Center>
                              <Badge
                                backgroundColor={"gray.300"}
                                color={"rgba(255,134,0,0.5)"}
                              >
                                Inactive
                              </Badge>
                            </Center>
                          )}
                        </Td>
                      </>
                    ) : (
                      <>
                        <Td textAlign={"center"}>
                          <Link to={`/client/${item._id}`}>
                            <Text color={"nevtis.1"} as={"u"}>
                              {item.companyName}
                            </Text>
                          </Link>
                        </Td>

                        <Td>
                          {item.isActivated ? (
                            <Center>
                              <Badge
                                backgroundColor={"rgba(255,134,0)"}
                                color="gray.50"
                                p={1}
                                borderRadius={5}
                              >
                                Active
                              </Badge>
                            </Center>
                          ) : (
                            <Center>
                              <Badge
                                backgroundColor={"gray.300"}
                                color={"rgba(255,134,0,0.5)"}
                              >
                                Inactive
                              </Badge>
                            </Center>
                          )}
                        </Td>
                      </>
                    )}
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        sections={sections}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </Box>
  );
};

export default TableUsers;
