import React, { useState } from "react";
import QrScanner from "react-qr-scanner";
import { Box, Center, Button } from "@chakra-ui/react";

const ScanQr = ({ handleForm }) => {
  const [camOn, setCamOn] = useState(false);

  const parseData = (text) => {
    const posicionEmailInicio = text.indexOf("csc:") + "csc:".length;
    const posicionPasswordInicio = text.indexOf(".com:") + ".com:".length;
    const posicionPasswordFin = text.indexOf("@NEVTIS");

    if (
      posicionEmailInicio > -1 &&
      posicionPasswordInicio > -1 &&
      posicionPasswordFin > -1
    ) {
      const email = text.slice(
        posicionEmailInicio,
        posicionPasswordInicio - ":".length
      );
      const password = text.slice(posicionPasswordInicio, posicionPasswordFin);
      return { email, password };
    } else {
      return { email: "", password: "" };
    }
  };

  const handleScan = (scannedData) => {
    if (scannedData) {
      const parsedData = parseData(scannedData.text);
      handleForm({ email: parsedData.email, password: parsedData.password });
      setCamOn(false);
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

  const handleStartScan = () => {
    setCamOn(true);
  };

  return (
    <Box>
      <Center>
        <Box>
          {!camOn && (
            <Center zIndex={1}>
              <Button
                mt={10}
                size="xs"
                w="full"
                colorScheme="green"
                className="w-full"
                onClick={handleStartScan}
              >
                Scan QrCode
              </Button>
            </Center>
          )}

          {camOn && (
            <Center mb={4}>
              <QrScanner
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: "70%", maxWidth: "300px" }}
                constraints={{ video: { facingMode: "environment" } }}
              />
            </Center>
          )}
        </Box>
      </Center>
    </Box>
  );
};

export default ScanQr;
