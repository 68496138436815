import React from "react";
import { Container, Grid, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import HomeGraph from "../../commons/admin/HomeGraph";

const Dashboard = () => {
  const admin = useSelector((state) => state.admins);
  const seller = useSelector((state) => state.sellers);
  const domains = useSelector((state) => state.domains);
  const company = useSelector((state) => state.clients);
  const softphoneUsers = useSelector((state) => state.softphoneUsers);

  const dominiosSinCliente = domains.domains.filter((domain) => {
    return !company.some(
      (cliente) => cliente.domain.domain_uuid === domain.domain_uuid
    );
  });

  return (
    <Container maxW="container.lg">
      <Flex direction="column" mt={10} justifyContent="center">
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(5, 1fr)",
          ]}
          gap={6}
        >
          <HomeGraph
            firtsValue={domains.domains.length - dominiosSinCliente.length}
            secondValue={dominiosSinCliente.length}
            firtsLabel={"Customers"}
            secondLabel={"Available"}
            tittle={"Customers"}
          />

          <HomeGraph
            firtsLabel={"Admin"}
            firtsValue={admin.length}
            tittle={"Admins"}
          />
          <HomeGraph
            firtsLabel={"Seller"}
            firtsValue={seller.length}
            tittle={"Sellers"}
          />
          <HomeGraph
            firtsLabel={"Softphone Users"}
            firtsValue={softphoneUsers.length}
            tittle={"Softphone U"}
          />
        </Grid>
      </Flex>
    </Container>
  );
};

export default Dashboard;
