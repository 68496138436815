import React from "react";
import { Box, Text } from "@chakra-ui/react";

const SummaryComponent = ({
  startSelectedDate,
  endSelectedDate,
  totalCalls,
  fullscreen,
}) => {
  return (
    <Box>
      {startSelectedDate === endSelectedDate ? (
        <Text
          color="nevtis.1"
          fontWeight="bold"
          fontSize="xl"
          ml={1}
          textAlign="center"
          my={5}
        >
          {startSelectedDate}
        </Text>
      ) : (
        <Text
          color="nevtis.1"
          fontWeight="bold"
          fontSize="xl"
          ml={1}
          textAlign="center"
          my={5}
        >
          {startSelectedDate} / {endSelectedDate}
        </Text>
      )}
      <Text
        color="nevtis.1"
        fontWeight="bold"
        textAlign="center"
        fontSize={fullscreen ? "4xl" : "xl"}
      >
        Total Calls: {totalCalls}
      </Text>
    </Box>
  );
};

export default SummaryComponent;
