import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Container,
  Flex,
  Heading,
  VStack,
  Wrap,
  Link,
  Box,
  HStack,
  useMediaQuery,
  WrapItem,
  IconButton,
  Center,
  Text,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { MdPhone, MdEmail, MdLocationOn, MdFacebook } from "react-icons/md";
import { BsGithub, BsDiscord } from "react-icons/bs";
import { TbWorld } from "react-icons/tb";
import { useForm } from "react-hook-form";
import { forgotPassRequest } from "../../store/reducers/user.reducer";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPassword = () => {
  const { register, handleSubmit } = useForm();
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-start",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const captcha = useRef(null);
  const forgotPass = async (e) => {
    const { email } = e;
    const recaptchaToken = await new Promise((resolve) => {
      const token = captcha.current.getValue();
      resolve(token);
    });

    if (!recaptchaToken) {
      Toast.fire({
        icon: "error",
        title: "Please complete the reCAPTCHA challenge.",
      });
      return;
    } else {
      const resp = await dispatch(forgotPassRequest(email));
      if (resp.payload.message) {
        Toast.fire({
          icon: "success",
          title: `${resp.payload.message}`,
        });
        navigate("/");
      } else {
        Toast.fire({
          icon: "question",
          title: `${resp.payload}`,
        });
      }
    }
  };

  return (
    <Container maxW="full" mt={10} centerContent overflow="hidden">
      <Flex>
        <Box
          bg="gray.100"
          color="gray.700"
          borderRadius="xl"
          border="1px solid #cbd5e0"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 5, lg: 16 }}
          shadow="xl"
        >
          <Box p={4}>
            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
              {isLargerThan800 ? (
                <>
                  <WrapItem>
                    <Box>
                      <Heading color={"rgba(255,134,0)"}>CONTACT US!</Heading>

                      <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                        <VStack pl={0} spacing={3} alignItems="flex-start">
                          <Button
                            size="md"
                            height="48px"
                            width="200px"
                            variant="ghost"
                            color="gray.600"
                            _hover={{ border: "2px solid #0987a0" }}
                            leftIcon={
                              <MdPhone color="rgba(255,134,0)" size="30px" />
                            }
                          >
                            (855) 442-7107
                          </Button>
                          <Button
                            size="md"
                            height="48px"
                            width="200px"
                            variant="ghost"
                            color="gray.600"
                            _hover={{ border: "2px solid #0987a0" }}
                            leftIcon={
                              <MdEmail color="rgba(255,134,0)" size="30px" />
                            }
                          >
                            hello@nevtis.com
                          </Button>
                          <Button
                            size="md"
                            height="48px"
                            width="200px"
                            variant="ghost"
                            color="gray.600"
                            _hover={{ border: "2px solid #0987a0" }}
                            leftIcon={
                              <MdLocationOn
                                color="rgba(255,134,0)"
                                size="22px"
                              />
                            }
                          >
                            Blvd Suite 101, Anaheim
                          </Button>
                          <Link href="https://nevtis.com/contact-us" isExternal>
                            <Button
                              size="md"
                              height="48px"
                              width="200px"
                              variant="ghost"
                              color="gray.600"
                              _hover={{ border: "2px solid #0987a0" }}
                              leftIcon={
                                <TbWorld color="rgba(255,134,0)" size="30px" />
                              }
                            >
                              www.nevtis.com
                            </Button>
                          </Link>
                        </VStack>
                      </Box>
                      <HStack
                        mt={{ lg: 10, md: 10 }}
                        spacing={5}
                        px={5}
                        alignItems="flex-start"
                      >
                        <IconButton
                          aria-label="facebook"
                          variant="ghost"
                          size="lg"
                          isRound={true}
                          _hover={{ bg: "rgba(255,134,0,0.1)" }}
                          icon={
                            <MdFacebook size="28px" color="rgba(255,134,0)" />
                          }
                        />
                        <IconButton
                          aria-label="github"
                          variant="ghost"
                          size="lg"
                          isRound={true}
                          _hover={{ bg: "rgba(255,134,0,0.1)" }}
                          icon={
                            <BsGithub size="28px" color="rgba(255,134,0)" />
                          }
                        />
                        <IconButton
                          aria-label="discord"
                          variant="ghost"
                          size="lg"
                          isRound={true}
                          _hover={{ bg: "rgba(255,134,0,0.1)" }}
                          icon={
                            <BsDiscord size="28px" color="rgba(255,134,0)" />
                          }
                        />
                      </HStack>
                    </Box>
                  </WrapItem>
                </>
              ) : (
                <></>
              )}
              <WrapItem>
                <Box bg="whiteAlpha.500" borderRadius="lg" shadow="lg">
                  <Box m={8} color="#0B0E3F" mt={10}>
                    <VStack spacing={5}>
                      <form onSubmit={handleSubmit(forgotPass)}>
                        <FormControl id="email" isRequired>
                          <FormLabel color={"rgba(255,134,0)"}>
                            Email address
                          </FormLabel>
                          <Input
                            type="email"
                            {...register("email")}
                            bg={"white.400"}
                            mb={2}
                          />
                        </FormControl>
                        <FormControl>
                          <ReCAPTCHA
                            sitekey="6Ld1OeQmAAAAANI1clqIrRwdg8K3Y5Q2VzTPtbDv"
                            ref={captcha}
                          />
                        </FormControl>
                        <Text color={"rgba(255,134,0,0.5)"} maxW={80}>
                          Password recovery process to help you regain access to
                          your account. By submitting your email, you will be
                          guided through the necessary steps to reset your
                          password securely.
                        </Text>
                        <Center>
                          <Button
                            mt={12}
                            type="submit"
                            bg={"rgba(255,134,0)"}
                            color={"gray.100"}
                            _hover={{
                              bg: "rgba(255,134,0,0.5)",
                            }}
                          >
                            Submits
                          </Button>
                        </Center>
                      </form>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default ForgotPassword;
