import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Pagination from "../../../commons/general/Pagination";
import CreateCategories from "./CreateCategories";
import { showToast } from "../../../commons/SweetAlertToast";

const TableCategories = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [isDesktop] = useMediaQuery("(min-width: 800px)");

  useEffect(() => {
    axios
      .get("https://api.nevtis.com/marketplace/categories/all")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleDeleteCategory = async () => {
    try {
      await axios.delete(
        `https://api.nevtis.com/marketplace/categories/delete/${selectedCategory._id}`
      );
      setCategories((prevCategories) =>
        prevCategories.filter((cat) => cat._id !== selectedCategory._id)
      );
      showToast("Category Deleted", "success");
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting category:", error);
      // Handle error
    }
  };

  const handleEditCategory = async () => {
    try {
      const res = await axios.put(
        `https://api.nevtis.com/marketplace/categories/update/${selectedCategory._id}`,
        {
          name: editedName,
          description: editedDescription,
        }
      );
      showToast("Category Updated", "success");
      setCategories((prevCategories) =>
        prevCategories.map((cat) =>
          cat._id === selectedCategory._id
            ? { ...cat, name: res.data.name, description: res.data.description }
            : cat
        )
      );
      setIsEditing(false);
      setSelectedCategory(null);
      setEditedName("");
      setEditedDescription("");
    } catch (error) {
      console.error("Error editing category:", error);
      // Handle error
    }
  };

  const handleOpenEditModal = (category) => {
    setSelectedCategory(category);
    setEditedName(category.name);
    setEditedDescription(category.description);
    setIsEditing(true);
  };

  const handleCloseEditModal = () => {
    setIsEditing(false);
    setSelectedCategory(null);
    setEditedName("");
    setEditedDescription("");
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleInputPerPage = (value) => {
    setItemsPerPage(Number(value));
  };

  const filteredData = categories.filter((item) =>
    item.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const sections = filteredData.reduce(
    (acc, item, index) =>
      index % itemsPerPage === 0
        ? [...acc, filteredData.slice(index, index + itemsPerPage)]
        : acc,
    []
  );

  const currentSection = sections[currentPage - 1];

  const path = useLocation();

  return (
    <Box mt={4} mx={10}>
      <Stack direction={{ base: "column", md: "row" }} spacing={10} mb={4}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by Category Name"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <Flex justifyContent="flex-end" alignItems="center">
          <Text color={"nevtis.1"} mr={2}>
            Items per Page
          </Text>
          <NumberInput
            size="xs"
            maxW={16}
            defaultValue={itemsPerPage}
            min={1}
            onChange={handleInputPerPage}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Stack>

      <CreateCategories setCategories={setCategories} />

      <TableContainer
        mt={4}
        maxHeight={path.pathname === "/client" ? "320px" : "800px"}
        overflowY="scroll"
      >
        <Table variant="simple" size={isDesktop ? "2xl" : "xs"}>
          <Thead
            position={"sticky"}
            zIndex={1}
            top={0}
            bg={"white"}
            fontSize={!isDesktop && "xs"}
            textAlign={!isDesktop && "center"}
          >
            <Tr>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Name
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Description
              </Th>
              <Th
                color="rgba(255,134,0)"
                height="50px"
                textAlign="center"
                fontSize="sm"
              >
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentSection &&
              currentSection.map((item) => (
                <Tr key={item._id}>
                  <Td textAlign="center">{item.name}</Td>
                  <Td textAlign="center">{item.description}</Td>
                  <Td textAlign="center">
                    <Button
                      colorScheme="blue"
                      size="xs"
                      mr={2}
                      onClick={() => handleOpenEditModal(item)}
                    >
                      Edit
                    </Button>
                    <Button
                      colorScheme="red"
                      size="xs"
                      onClick={() => {
                        setSelectedCategory(item);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        sections={sections}
        currentPage={currentPage}
        handlePageChange={setCurrentPage}
      />

      <Modal isOpen={isEditing} onClose={handleCloseEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Input
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                placeholder="Category Name"
              />
              <Input
                value={editedDescription}
                onChange={(e) => setEditedDescription(e.target.value)}
                placeholder="Category Description"
              />
            </Stack>
            <Button mt={4} colorScheme="blue" onClick={handleEditCategory}>
              Save Changes
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this category?</Text>
            <Button mt={4} colorScheme="red" onClick={handleDeleteCategory}>
              Confirm Delete
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TableCategories;
