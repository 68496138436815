import axios from "axios";
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {URL_USER} from "../../config/apiBaseURL";

const initialState = [];

export const getAllPbxExtension = createAsyncThunk(
  "GET_PBX_EXTENSIONS",
  async () => {
    const response = await axios.get(`${{URL_USER}}/pbxExtension/all`);
    return response.data;
  }
);
export const getAllPbxExtensionByCompany = createAsyncThunk(
  "GET_PBX_EXTENSIONS_BYCOMPANY",
  async (companyId) => {
    const response = await axios.get(
      `${{URL_USER}}/pbxExtension/all/${companyId}`
    );
    return response.data;
  }
);

export const createPbxExtension = createAsyncThunk(
  "CREATE_PBX_EXTENSION",
  async (data) => {
    const response = await axios.post(
      `${{URL_USER}}/pbxExtension/create`,
      data
    );
    return response.data;
  }
);

export const deletePbxExtension = createAsyncThunk(
  "DELETE_PBX_EXTENSION",
  async (id) => {
    const response = await axios.delete(
      `${{URL_USER}}/pbxExtension/delete/${id}`
    );
    return response.data;
  }
);

export const updatePbxExtension = createAsyncThunk(
  "UPDATE_PBX_EXTENSION",
  async (data) => {
    const response = await axios.put(
      `${{URL_USER}}/pbxExtension/update`,
      data
    );
    return response.data;
  }
);

const pbxExtensionReducer = createReducer(initialState, {
  [getAllPbxExtension.fulfilled]: (state, action) => action.payload,
  [getAllPbxExtensionByCompany.fulfilled]: (state, action) => action.payload,
  [createPbxExtension.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [deletePbxExtension.fulfilled]: (state, action) => {
    const deletedId = action.meta.arg;
    return state.filter((pbxExtension) => pbxExtension._id !== deletedId);
  },
  [updatePbxExtension.fulfilled]: (state, action) => {
    const updatedPbxExtension = action.payload;
    const index = state.findIndex(
      (pbxExtension) => pbxExtension._id === updatedPbxExtension._id
    );
    if (index !== -1) {
      state[index] = updatedPbxExtension;
    }
  },
});

export default pbxExtensionReducer;
