import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateServiceCall } from "../../store/reducers/serviceCalls.reducer";
import { buscarDireccionEnGoogleMaps } from "../../helpers/googleMaps/googleMapsQuery";
import { GiCheckMark } from "react-icons/gi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdDoneAll, MdEditSquare } from "react-icons/md";
import { SiGooglemaps } from "react-icons/si";
import { headStyles, cellStyles, timeStyles } from "./TableStyles";
import { formatDate, formatTime } from "../../utils/formatUtils";
import ServiceCallFilters from "./ServiceCallFilters";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Textarea,
  Flex,
  Text,
} from "@chakra-ui/react";
import FormularioDireccion from "./FormularioDireccion";
import { showToast } from "../../commons/SweetAlertToast";

const TableComponent = ({ data, isCompleted }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedData, setSelectedData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [filterName, setFilterName] = useState("");
  const [filterCity, setFilterCity] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  useEffect(() => {
    if (selectedData) {
      setEditedData({ ...selectedData });
    }
  }, [selectedData]);

  const handleEdit = (rowData) => {
    setSelectedData(rowData);
    onOpen();
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSave = () => {
    dispatch(
      updateServiceCall({ id: selectedData._id, serviceCallData: editedData })
    );
    setEditedData(null);
    onClose();
  };

  const handleDone = (rowData) => {
    if (!rowData.billing || !rowData.docs) {
      showToast("Please select docs and billing", "error");
      return;
    }
    const updatedData = { ...rowData, made: true };
    dispatch(
      updateServiceCall({ id: rowData._id, serviceCallData: updatedData })
    );
  };

  const filteredData = useMemo(() => {
    return data.filter((rowData) => {
      const nameMatch = rowData.name
        .toLowerCase()
        .includes(filterName.toLowerCase());
      const cityMatch = rowData.city
        .toLowerCase()
        .includes(filterCity.toLowerCase());
      const startDateMatch =
        !filterStartDate || rowData.install >= filterStartDate;
      const endDateMatch = !filterEndDate || rowData.install <= filterEndDate;
      return nameMatch && cityMatch && startDateMatch && endDateMatch;
    });
  }, [data, filterName, filterCity, filterStartDate, filterEndDate]);

  // Pagination
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleFilterNameChange = (e) => setFilterName(e.target.value);

  const handleFilterCityChange = (e) => setFilterCity(e.target.value);

  const handleFilterStartDateChange = (e) => setFilterStartDate(e.target.value);

  const handleFilterEndDateChange = (e) => setFilterEndDate(e.target.value);

  const handleReset = () => {
    setFilterName("");
    setFilterCity("");
    setFilterStartDate("");
    setFilterEndDate("");
  };

  const handleQueryAddress = ({ address, city, state }) => {
    const maps = `${address}, ${city}, ${state}, EE. UU.`;
    buscarDireccionEnGoogleMaps(maps);
  };
  return (
    <Box mt={4}>
      {/* Filter */}
      <Box display="flex" justifyContent="space-between">
        <ServiceCallFilters
          filterName={filterName}
          handleFilterNameChange={handleFilterNameChange}
          filterCity={filterCity}
          handleFilterCityChange={handleFilterCityChange}
          filterStartDate={filterStartDate}
          handleFilterStartDateChange={handleFilterStartDateChange}
          filterEndDate={filterEndDate}
          handleFilterEndDateChange={handleFilterEndDateChange}
          handleReset={handleReset}
        />
        {/* Pagination Controls */}
        <Box
          mt={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            size="sm"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <IoIosArrowBack />
          </Button>
          <Box mx={2} fontWeight="bold">
            Page {currentPage} of {totalPages}
          </Box>
          <Button
            size="sm"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <IoIosArrowForward />
          </Button>
        </Box>
      </Box>

      <Table variant="simple" border="1px" borderColor="gray.300">
        <Thead>
          <Tr border="1px" borderColor="gray.300">
            <Th {...headStyles}>Ticket Number</Th>
            <Th {...headStyles}>Name</Th>
            <Th {...headStyles}>City</Th>
            <Th {...headStyles} width="5VW">
              Port
            </Th>
            <Th {...headStyles} width="11VW">
              Install
            </Th>
            <Th {...headStyles} width="5VW">
              Docs
            </Th>
            <Th {...headStyles} width="5VW">
              Billing
            </Th>
            <Th {...headStyles}>Notes</Th>
            <Th {...headStyles} width="10VW">
              Actions
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {paginatedData.map((rowData) => (
            <Tr key={rowData._id} border="1px" borderColor="gray.300" p={0}>
              <Td {...cellStyles}>{rowData.tickerNumber}</Td>
              <Td {...cellStyles}>{rowData.name}</Td>
              <Td {...cellStyles}>{rowData.city}</Td>
              <Td {...cellStyles}>{rowData.port}</Td>
              <Td {...cellStyles}>
                {formatDate(rowData.install)}
                <span style={timeStyles}> {formatTime(rowData.install)}</span>
              </Td>
              <Td {...cellStyles}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {rowData.docs ? <GiCheckMark color="orange" /> : null}
                </div>
              </Td>
              <Td {...cellStyles}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {rowData.billing ? <GiCheckMark color="orange" /> : null}
                </div>
              </Td>
              <Td {...cellStyles}>{rowData.notes}</Td>
              <Td {...headStyles} p={1} pl={4}>
                <Button
                  colorScheme="orange"
                  size="xs"
                  variant="outline"
                  onClick={() => handleEdit(rowData)}
                >
                  <MdEditSquare />
                </Button>
                {!isCompleted && (
                  <Button
                    colorScheme="orange"
                    size="xs"
                    ml={1}
                    onClick={() => handleDone(rowData)}
                  >
                    <MdDoneAll />
                  </Button>
                )}
                <Button
                  size="xs"
                  colorScheme="orange"
                  variant="outline"
                  ml={1}
                  onClick={() => handleQueryAddress(rowData)}
                >
                  <SiGooglemaps />
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {/*sigue este como componente ya está creado*/}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Flex>
                <FormLabel>Name:</FormLabel>
                <Input
                  name="name"
                  value={editedData?.name || ""}
                  onChange={handleInputChange}
                  size="sm"
                />
              </Flex>
            </FormControl>
            <FormControl mt={1}>
              <Flex>
                <FormLabel>Address:</FormLabel>
                <Input
                  name="address"
                  value={editedData?.address || ""}
                  onChange={handleInputChange}
                  size="sm"
                />
                <Text fontSize="xs" mt={3}>
                  {editedData?.city || ""}/{editedData?.state || ""}
                </Text>
              </Flex>
            </FormControl>
            <FormControl mt={1}>
              <Flex>
                <FormLabel>Port:</FormLabel>
                <Input
                  name="port"
                  value={editedData?.port || ""}
                  onChange={handleInputChange}
                  size="sm"
                />
              </Flex>
            </FormControl>
            <FormControl mt={1}>
              <Flex>
                <FormLabel>Install:</FormLabel>
                <Input
                  name="install"
                  type="datetime-local"
                  value={editedData?.install || ""}
                  onChange={handleInputChange}
                  size="sm"
                />
              </Flex>
            </FormControl>
            <Flex>
              <FormControl>
                <FormLabel>Billing</FormLabel>
                <Switch
                  name="billing"
                  colorScheme="orange"
                  isChecked={editedData?.billing || false}
                  onChange={handleInputChange}
                  icon={<GiCheckMark />}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Docs</FormLabel>
                <Switch
                  name="docs"
                  colorScheme="orange"
                  isChecked={editedData?.docs || false}
                  onChange={handleInputChange}
                  icon={<GiCheckMark />}
                />
              </FormControl>
            </Flex>
            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Textarea
                name="notes"
                value={editedData?.notes || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormularioDireccion
              city={editedData?.city || ""}
              state={editedData?.state || ""}
              address={editedData?.address || ""}
            />
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button
              colorScheme="orange"
              mr={3}
              onClick={handleSave}
              variant="solid"
            >
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TableComponent;
