import React from "react";
import { useParams } from "react-router-dom";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Heading,
  Center,
  Flex,
  Menu,
  Link as LinkChakra,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Button,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Loading from "../../../commons/general/Loading";
import { RiMailSendLine } from "react-icons/ri";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { IoSettingsSharp } from "react-icons/io5";
import SellerDetail from "./SellerDetail";
import Activities from "../../Actions/Activities";
import Comunications from "../../Actions/Comunications";
import EditSeller from "./EditSeller";
import DeleteSeller from "./DeleteSeller";
import TableSubVendors from "./TableSubVendors";
import axios from "axios";
import { showToast } from "../../../commons/SweetAlertToast";

const SellerDetailsTabs = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const userLocalStorg = JSON.parse(localStorage.getItem("user"));
  const master = userLocalStorg.token;
  const { sellerId } = useParams();
  const allSellers = useSelector((state) => state.sellers);
  const user = useSelector((state) => state.user);
  const seller = allSellers.find((item) => item._id === sellerId);

  const sendAccessCode = (user) => {
    if (!user.email) {
      return;
    }
    axios
      .post("https://api.nevtis.com/comunication/email/access-code", {
        email: user.email,
        token: user.activationCode,
        name: user.name,
        role: user.role,
      })
      .then((res) => {
        res.status === 200
          ? showToast("Access Code Sent", "success")
          : showToast("Error", "error");
      });
  };

  if (!seller) {
    return <Loading />;
  }

  return (
    <Tabs
      mt={{ base: 4, md: 10 }}
      width="100%"
      overflowX="auto"
      isFitted={isLargerThan768}
    >
      <Center>
        <Flex>
          <Heading
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight="bold"
            textAlign="center"
            mr="4"
            color={"nevtis.1"}
          >
            {seller.name}
          </Heading>
          {user.superAdmin && (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<IoSettingsSharp />}
                variant="outline"
              />
              <MenuList>
                <LinkChakra
                  href={`https://partnerportal.nevtis.com/${master}/${seller.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MenuItem>Login as {seller.name}</MenuItem>
                </LinkChakra>
                <EditSeller user={seller} />
                <DeleteSeller user={seller} />
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Center>
      <Center mb={4}>
        {seller.isActivated ? (
          <Flex>
            <Text
              size={"xs"}
              color="green"
              borderRadius="md"
              px="2"
              py="2"
              display={"flex"}
            >
              <MdCheckCircle style={{ marginRight: "2px" }} /> Active
            </Text>
          </Flex>
        ) : (
          <Flex>
            <Text
              size={"xs"}
              color="red"
              borderRadius="md"
              px="2"
              py="2"
              display={"flex"}
            >
              <MdCancel style={{ marginRight: "4px" }} />
              Inactive
            </Text>
          </Flex>
        )}
        {!seller.isActivated && (
          <Button
            size={"xs"}
            textAlign={"center"}
            bg={"nevtis.1.4"}
            _hover={{ bg: "nevtis.1" }}
            onClick={() => sendAccessCode(seller)}
          >
            <RiMailSendLine style={{ marginRight: "2px" }} />
            Access Code
          </Button>
        )}
      </Center>
      <TabList overflowX={isLargerThan768 ? "visible" : "auto"}>
        <Tab>Details</Tab>
        <Tab>Leads</Tab>
        <Tab>Customers</Tab>
        <Tab>Sub Vendors</Tab>
        <Tab>Commissions</Tab>
        <Tab>Communications</Tab>
        <Tab>Activities</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Box width="100%">
            <SellerDetail seller={seller} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">Leads</Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">Customers</Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <TableSubVendors seller={seller} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">Commissions</Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <Comunications user={seller} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <Activities user={seller} />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default SellerDetailsTabs;
