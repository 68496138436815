import { useForm } from "react-hook-form";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  useDisclosure,
  useToast,
  Stack,
  FormControl,
  FormLabel,
  Input,
  MenuItem,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateSoftphoneUser } from "../../../store/reducers/softphoneUser.reducer";

const EditSoftphone = ({ user }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [employeeData, setEmployeeData] = useState([user]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onChange = (e, _id) => {
    const { name, value } = e.target;
    const editData = employeeData.map((item) =>
      item._id === _id && name ? { ...item, [name]: value } : item
    );
    setEmployeeData(editData);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const _id = user._id;
    const resp = await dispatch(updateSoftphoneUser({ ...data, _id }));
    setLoading(false);
    onClose();
    toast({
      title: resp.payload ? `User Updated` : `Error`,
      status: resp.payload ? "success" : "error",
      duration: 4000,
      isClosable: true,
      position: "bottom-left",
    });
  };

  return (
    <>
      <MenuItem onClick={onOpen}>Edit {user.name}</MenuItem>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">Edit</DrawerHeader>
          <DrawerBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <>
                {employeeData.map(({ _id, name, address, phone }) => (
                  <Stack spacing={2} key={_id}>
                    <FormControl>
                      <FormLabel>Name</FormLabel>
                      <Input
                        {...register("name")}
                        value={name}
                        onChange={(e) => onChange(e, _id)}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Address</FormLabel>
                      <Input
                        {...register("address")}
                        value={address}
                        onChange={(e) => onChange(e, _id)}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Phone Number</FormLabel>
                      <Input
                        {...register("phone")}
                        value={phone}
                        onChange={(e) => onChange(e, _id)}
                      />
                    </FormControl>

                    <Stack spacing={10}>
                      <Stack
                        direction={{ base: "column", sm: "row" }}
                        align={"start"}
                        justify={"space-between"}
                      ></Stack>
                      <Button
                        isLoading={loading}
                        type="submit"
                        bg={"rgba(255,134,0)"}
                        color={"black"}
                        _hover={{
                          bg: "rgba(255,134,0,0.5)",
                        }}
                      >
                        Edit
                      </Button>
                    </Stack>
                  </Stack>
                ))}
              </>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default EditSoftphone;
