import axios from "axios";
import React, { useEffect, useState } from "react";
import { Box, Text, Grid, Flex, useMediaQuery } from "@chakra-ui/react";
import { FaRegCheckCircle, FaRegClock, FaRegTimesCircle } from "react-icons/fa";
import { BiWrench } from "react-icons/bi";
import Navbar from "../components/general/Navbar";
import { useSelector } from "react-redux";

const StatusDigitalOcean = () => {
  const [statusDigitalOcean, setStatusDigitalOcean] = useState([]);
  const user = useSelector((state) => state.user);
  const userLogueado = user.state !== "rejected";
  const [isDesktop] = useMediaQuery("(min-width: 800px)");

  useEffect(() => {
    axios
      .get("https://status.digitalocean.com/api/v2/summary.json")
      .then((res) => {
        setStatusDigitalOcean(res.data);
      });
  }, []);

  const getStatusIcon = (status) => {
    switch (status) {
      case "operational":
        return <FaRegCheckCircle fontSize={24} color="green" />;
      case "degraded_performance":
        return <FaRegClock color="yellow" fontSize={24} />;
      case "partial_outage":
        return <FaRegTimesCircle color="orange" fontSize={24} />;
      case "major_outage":
        return <FaRegTimesCircle color="red" fontSize={24} />;
      case "maintenance":
        return <BiWrench color="blue" fontSize={24} />;
      default:
        return null;
    }
  };

  const CellsStatus = ({ item }) => (
    <Flex
      key={item.id}
      border={"1px"}
      borderColor={"nevtis.1"}
      borderRadius={"xl"}
      px={5}
      py={2}
      textAlign={"center"}
    >
      <Text flex="1" fontSize="xl" mr={4}>
        {item.name}
      </Text>
      <Box alignItems={"flex-end"}>{getStatusIcon(item.status)}</Box>
    </Flex>
  );

  const statusMappings = {
    operational: "Operational",
    degraded_performance: "Degraded Performance",
    partial_outage: "Partial Outage",
    major_outage: "Major Outage",
    maintenance: "Maintenance",
  };

  return (
    <Box ml={userLogueado && isDesktop && 60}>
      {!userLogueado && <Navbar />}
      <Text fontSize="2xl" fontWeight={"bold"} color={"gray.500"} ml={4} mt={5}>
        DigitalOcean Services Status
      </Text>

      {statusDigitalOcean.length === 0 ? (
        <Text>loading</Text>
      ) : (
        <Box mt={5} px={userLogueado ? 0 : 20} mx={userLogueado ? 0 : 20}>
          <Box>
            <Text
              my={10}
              fontSize="2xl"
              textAlign={"center"}
              fontWeight={"bold"}
              color={"nevtis.1"}
            >
              Global Services Status
            </Text>
            <Grid
              templateColumns="repeat(auto-fill, minmax(350px, 2fr))"
              gap={4}
              justifyContent="center"
            >
              {statusDigitalOcean.components
                .filter(
                  (item) => item.group_id === null && item.group === false
                )
                .map((item) => (
                  <CellsStatus key={item.id} item={item} />
                ))}
            </Grid>
          </Box>
          <Box>
            <Text
              my={10}
              fontSize="2xl"
              textAlign={"center"}
              fontWeight={"bold"}
              color={"nevtis.1"}
            >
              Regional Services Status
            </Text>
            <Grid
              templateColumns="repeat(auto-fill, minmax(350px, 1fr))"
              gap={4}
              justifyContent="center"
            >
              {statusDigitalOcean.components
                .filter((item) => item.group_id === null && item.group === true)
                .map((item) => (
                  <CellsStatus key={item.id} item={item} />
                ))}
            </Grid>
          </Box>
        </Box>
      )}

      <Flex
        border={"1px"}
        borderRadius={"2xl"}
        borderColor={"nevtis.1"}
        justifyContent="center"
        mb={5}
        flexWrap="wrap"
        p={2}
        mt={10}
      >
        {Object.entries(statusMappings).map(([status, description]) => (
          <Flex
            key={status}
            flexDirection={{ base: "column", md: "row" }}
            alignItems="center"
            mx={2}
            my={2}
          >
            <Text mr={{ base: 0, md: 2 }} mb={{ base: 2, md: 0 }}>
              {description}
            </Text>
            {getStatusIcon(status)}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default StatusDigitalOcean;
