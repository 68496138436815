import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateServiceCall } from "../../store/reducers/serviceCalls.reducer";
import { buscarDireccionEnGoogleMaps } from "../../helpers/googleMaps/googleMapsQuery";
import { GiCheckMark } from "react-icons/gi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdDoneAll, MdEditSquare, MdRestartAlt } from "react-icons/md";
import { SiGooglemaps } from "react-icons/si";
import { headStyles, cellStyles, timeStyles } from "./TableStyles";
import { formatDate, formatTime } from "../../utils/formatUtils";
import ServiceCallFilters from "./ServiceCallFilters";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Textarea,
  Flex,
  Text,
} from "@chakra-ui/react";
import FormularioDireccion from "./FormularioDireccion";
import { showToast } from "../../commons/SweetAlertToast";
import axios from "axios";

const Tickets = ({ isCompleted }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const customers = useSelector((state) => state.clients);
  const admins = useSelector((state) => state.admins);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [filterName, setFilterName] = useState("");
  const [filterCity, setFilterCity] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const findCustomer = (id) => {
    const client = customers.find((cust) => {
      return cust._id === id;
    });
    return client;
  };
  const findAdmin = (id) => {
    const client = admins.find((cust) => {
      return cust._id === id;
    });
    return client;
  };

  useEffect(() => {
    axios.get("https://api.nevtis.com/interaction/tickets/all").then((res) => {
      setData(res.data);
    });
  }, [data]);

  useEffect(() => {
    if (selectedData) {
      setEditedData({ ...selectedData });
    }
  }, [selectedData]);

  const handleEdit = (rowData) => {
    setSelectedData(rowData);
    onOpen();
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSave = () => {
    dispatch(
      updateServiceCall({ id: selectedData._id, serviceCallData: editedData })
    );
    setEditedData(null);
    onClose();
  };

  const handleDone = (rowData) => {
    if (!rowData.billing || !rowData.docs) {
      showToast("Please select docs and billing", "error");
      return;
    }
    const updatedData = { ...rowData, made: true };
    dispatch(
      updateServiceCall({ id: rowData._id, serviceCallData: updatedData })
    );
  };

  const filteredData = useMemo(() => {
    return data.filter((rowData) => {
      const nameMatch = findCustomer(rowData.company)
        .companyName.toLowerCase()
        .includes(filterName.toLowerCase());

      const startDateMatch =
        !filterStartDate || rowData.install >= filterStartDate;
      const endDateMatch = !filterEndDate || rowData.install <= filterEndDate;
      return nameMatch && startDateMatch && endDateMatch;
    });
  }, [data, filterName, filterCity, filterStartDate, filterEndDate]);

  // Pagination
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleFilterNameChange = (e) => setFilterName(e.target.value);

  const handleFilterCityChange = (e) => setFilterCity(e.target.value);

  const handleFilterStartDateChange = (e) => setFilterStartDate(e.target.value);

  const handleFilterEndDateChange = (e) => setFilterEndDate(e.target.value);

  const handleReset = () => {
    setFilterName("");
    setFilterCity("");
    setFilterStartDate("");
    setFilterEndDate("");
  };

  const handleQueryAddress = ({ address, city, state }) => {
    const maps = `${address}, ${city}, ${state}, EE. UU.`;
    buscarDireccionEnGoogleMaps(maps);
  };
  return (
    <Box mt={4}>
      {/* Filter */}
      <Box display="flex" justifyContent="space-between">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Box>
            <FormLabel>Filter: by Name</FormLabel>
            <Input
              value={filterName}
              onChange={handleFilterNameChange}
              size="sm"
            />
          </Box>

          <Box>
            <Button
              onClick={handleReset}
              size="md"
              colorScheme="orange"
              variant="outline"
              ml={1}
            >
              <MdRestartAlt />
            </Button>
          </Box>
        </Box>
        {/* Pagination Controls */}
        <Box
          mt={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            size="sm"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <IoIosArrowBack />
          </Button>
          <Box mx={2} fontWeight="bold">
            Page {currentPage} of {totalPages}
          </Box>
          <Button
            size="sm"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <IoIosArrowForward />
          </Button>
        </Box>
      </Box>

      <Table variant="simple" border="1px" borderColor="gray.300">
        <Thead>
          <Tr border="1px" borderColor="gray.300">
            <Th {...headStyles}>Ticket Number</Th>
            <Th {...headStyles}>Status</Th>
            <Th {...headStyles}>Company Name</Th>
            <Th {...headStyles}>Customer Notes</Th>
            <Th {...headStyles}>Internal Notes</Th>
            <Th {...headStyles}>User</Th>
            <Th {...headStyles} width="10VW">
              Actions
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((rowData) => (
            <Tr key={rowData._id} border="1px" borderColor="gray.300" p={0}>
              <Td {...cellStyles}>{rowData.ticketNumber}</Td>
              <Td {...cellStyles}>{rowData.status}</Td>
              <Td {...cellStyles}>
                {findCustomer(rowData.company).companyName}
              </Td>
              <Td {...cellStyles}>{rowData.customerNotes}</Td>
              <Td {...cellStyles}>{rowData.internalNotes}</Td>
              <Td {...cellStyles}>{findAdmin(rowData.user).name}</Td>

              <Td {...headStyles} p={1} pl={4}>
                <Button
                  colorScheme="orange"
                  size="xs"
                  variant="outline"
                  onClick={() => handleEdit(rowData)}
                >
                  <MdEditSquare />
                </Button>
                {!isCompleted && (
                  <Button
                    colorScheme="orange"
                    size="xs"
                    ml={1}
                    onClick={() => handleDone(rowData)}
                  >
                    <MdDoneAll />
                  </Button>
                )}
                <Button
                  size="xs"
                  colorScheme="orange"
                  variant="outline"
                  ml={1}
                  onClick={() => handleQueryAddress(rowData)}
                >
                  <SiGooglemaps />
                </Button>
              </Td>
            </Tr>
          ))}
          {/*         <Tr key={data._id} border="1px" borderColor="gray.300" p={0}>
            <Td {...cellStyles}>20007</Td>
            <Td {...cellStyles}>Open</Td>
            <Td {...cellStyles}>Tech Solutions</Td>
            <Td {...cellStyles}>El sistema no responde.</Td>
            <Td {...cellStyles}>Verificar los logs del servidor.</Td>
            <Td {...cellStyles}>John Doe</Td>

            <Td {...headStyles} p={1} pl={4}>
              <Button
                colorScheme="orange"
                size="xs"
                variant="outline"
                onClick={() => handleEdit(rowData)}
              >
                <MdEditSquare />
              </Button>

              <Button
                size="xs"
                colorScheme="orange"
                variant="outline"
                ml={1}
                onClick={() => handleQueryAddress(rowData)}
              >
                <SiGooglemaps />
              </Button>
            </Td>
          </Tr> */}
        </Tbody>
      </Table>
      {/*sigue este como componente ya está creado*/}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Flex>
                <FormLabel>Name:</FormLabel>
                <Input
                  name="name"
                  value={editedData?.name || ""}
                  onChange={handleInputChange}
                  size="sm"
                />
              </Flex>
            </FormControl>
            <FormControl mt={1}>
              <Flex>
                <FormLabel>Address:</FormLabel>
                <Input
                  name="address"
                  value={editedData?.address || ""}
                  onChange={handleInputChange}
                  size="sm"
                />
                <Text fontSize="xs" mt={3}>
                  {editedData?.city || ""}/{editedData?.state || ""}
                </Text>
              </Flex>
            </FormControl>
            <FormControl mt={1}>
              <Flex>
                <FormLabel>Port:</FormLabel>
                <Input
                  name="port"
                  value={editedData?.port || ""}
                  onChange={handleInputChange}
                  size="sm"
                />
              </Flex>
            </FormControl>
            <FormControl mt={1}>
              <Flex>
                <FormLabel>Install:</FormLabel>
                <Input
                  name="install"
                  type="datetime-local"
                  value={editedData?.install || ""}
                  onChange={handleInputChange}
                  size="sm"
                />
              </Flex>
            </FormControl>
            <Flex>
              <FormControl>
                <FormLabel>Billing</FormLabel>
                <Switch
                  name="billing"
                  colorScheme="orange"
                  isChecked={editedData?.billing || false}
                  onChange={handleInputChange}
                  icon={<GiCheckMark />}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Docs</FormLabel>
                <Switch
                  name="docs"
                  colorScheme="orange"
                  isChecked={editedData?.docs || false}
                  onChange={handleInputChange}
                  icon={<GiCheckMark />}
                />
              </FormControl>
            </Flex>
            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Textarea
                name="notes"
                value={editedData?.notes || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormularioDireccion
              city={editedData?.city || ""}
              state={editedData?.state || ""}
              address={editedData?.address || ""}
            />
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button
              colorScheme="orange"
              mr={3}
              onClick={handleSave}
              variant="solid"
            >
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Tickets;
