import React, { useEffect, useState } from "react";
import { Box, Text, Input, VStack, Flex, Image, Icon } from "@chakra-ui/react";
import {
  createChat,
  getMessagesByChatId,
} from "../../store/reducers/chat.reducer";
import { useDispatch, useSelector } from "react-redux";
import avatar from "../../assets/profile.svg";
import {
  usePotentialChats,
  updateCurrentChat,
} from "../../store/reducers/chat.reducer";
import { MdFiberManualRecord } from "react-icons/md";

const PotentialChats = ({ user, chats }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const onlineUsers = useSelector((state) => state.chats.onlineUsers);
  const potentialChats = usePotentialChats(user, chats);

  const checkUserIdExists = (recipientUserId) => {
    return onlineUsers.some((item) => item.userId === recipientUserId);
  };

  useEffect(() => {
    const results = potentialChats.filter((potentialUser) =>
      potentialUser.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [potentialChats, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateChat = async (secondId) => {
    setSearchTerm("");
    const newChat = await dispatch(createChat({ firstId: user._id, secondId }));
    const currentChat = await dispatch(updateCurrentChat(newChat.payload));
    await dispatch(getMessagesByChatId(currentChat.payload._id));
  };

  return (
    <Box position="relative" width={"500px"}>
      <Input
        type="text"
        bg={"gray.300"}
        borderColor={"white"}
        placeholder="Look for people..."
        value={searchTerm}
        onChange={handleSearchChange}
      />

      {searchTerm !== "" && searchResults.length > 0 && (
        <Box
          position="absolute"
          zIndex={1}
          width="100%"
          borderRadius={"xl"}
          boxShadow={"2xl"}
        >
          {searchResults.map((potentialUser) => (
            <VStack
              spacing="0"
              align="stretch"
              display={"flex"}
              bg="gray.300"
              p={2}
              onClick={() => handleCreateChat(potentialUser._id)}
              key={potentialUser._id}
              cursor="pointer"
              _hover={{ bg: "gray.400" }}
            >
              <Flex align="center">
                <Box boxSize="30px" mr={2}>
                  <Image src={avatar} />
                </Box>

                <Icon
                  as={MdFiberManualRecord}
                  color={
                    checkUserIdExists(potentialUser._id) // Utiliza potentialUser._id en lugar de recipientUser?._id
                      ? "green.600"
                      : "red.600"
                  }
                  size={"5px"}
                />
                <Text fontWeight="bold" fontSize="sm" color={"gray.600"} ml={1}>
                  {potentialUser?.name}
                </Text>
              </Flex>
            </VStack>
          ))}
        </Box>
      )}

      {searchTerm !== "" && searchResults.length === 0 && (
        <Text color={"gray.300"} p={2}>
          Not Found
        </Text>
      )}
    </Box>
  );
};

export default PotentialChats;
