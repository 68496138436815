import React from "react";
import { useParams } from "react-router-dom";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Heading,
  Center,
  Flex,
  Menu,
  Link as LinkChakra,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Button,
  Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Loading from "../../../commons/general/Loading";
import { RiMailSendLine } from "react-icons/ri";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { IoSettingsSharp } from "react-icons/io5";
import AdminDetail from "./AdminDetail";
import Activities from "../../Actions/Activities";
import Comunications from "../../Actions/Comunications";
import EditAdmin from "./EditAdmin";
import DeleteAdmin from "./DeleteAdmin";
import axios from "axios";
import { showToast } from "../../../commons/SweetAlertToast";

const AdminDetailsTabs = () => {
  const userLocalStorg = JSON.parse(localStorage.getItem("user"));
  const master = userLocalStorg.token;
  const { adminId } = useParams();
  const allAdmins = useSelector((state) => state.admins);
  const user = useSelector((state) => state.user);
  const admin = allAdmins.find((item) => item._id === adminId);

  const sendAccessCode = (user) => {
    axios
      .post("https://api.nevtis.com/comunication/email/access-code", {
        email: user.email,
        token: user.activationCode,
        name: user.name,
        role: user.role,
      })
      .then((res) => {
        res.status === 200
          ? showToast("Access Code Sent", "success")
          : showToast("Error", "error");
      });
  };
  if (!admin) {
    return <Loading />;
  }

  return (
    <Tabs mt={10} width="100%">
      <Center>
        <Flex>
          <Heading
            fontSize="4xl"
            fontWeight="bold"
            textAlign="center"
            mr="4"
            color={"nevtis.1"}
          >
            {admin.name}
          </Heading>
          {user.superAdmin && (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<IoSettingsSharp />}
                variant="outline"
              />
              <MenuList>
                <LinkChakra
                  href={`https://clientspace.nevtis.com/${master}/${admin.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MenuItem>Login as {admin.name}</MenuItem>
                </LinkChakra>

                <EditAdmin user={admin} />
                <DeleteAdmin user={admin} />
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Center>
      <Center mb={1}>
        {admin.isActivated ? (
          <Flex>
            <Text
              size={"xs"}
              color="green"
              borderRadius="md"
              px="2"
              py="2"
              display={"flex"}
            >
              <MdCheckCircle style={{ marginRight: "2px" }} /> Active
            </Text>
          </Flex>
        ) : (
          <Flex>
            <Text
              size={"xs"}
              color="red"
              borderRadius="md"
              px="2"
              py="2"
              display={"flex"}
            >
              <MdCancel style={{ marginRight: "4px" }} />
              Inactive
            </Text>
          </Flex>
        )}
        {!admin.isActivated && (
          <Button
            size={"xs"}
            textAlign={"center"}
            bg={"nevtis.1.4"}
            _hover={{ bg: "nevtis.1" }}
            onClick={() => sendAccessCode(admin)}
          >
            <RiMailSendLine style={{ marginRight: "2px" }} />
            Access Code
          </Button>
        )}
      </Center>
      <TabList>
        <Tab>Details</Tab>
        <Tab>Comunications</Tab>
        <Tab>Activities</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Box width="100%">
            <AdminDetail admin={admin} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <Comunications user={admin} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width="100%">
            <Activities user={admin} />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default AdminDetailsTabs;
