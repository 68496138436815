import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure,
  ButtonGroup,
  MenuItem,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { deleteSeller } from "../../../store/reducers/seller.reducer";
import { useNavigate } from "react-router-dom";

const DeleteSeller = ({ user }) => {
  const { isOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteUser = async () => {
    Swal.fire({
      title: `Delete ${user.name}?`,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSeller(user._id)).then((resp) => {
          if (resp) {
            Swal.fire("Deleted!", "", "success");
            navigate("/seller");
          }
        });
      }
    });
  };

  return (
    <>
      <MenuItem onClick={deleteUser}>Delete </MenuItem>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <h1 aria-hidden="true"></h1>
        </PopoverTrigger>

        <PopoverContent>
          <PopoverHeader fontWeight="semibold">
            Delete {user.name}?
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>Are you sure?</PopoverBody>
          <PopoverFooter display="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteUser}>
                Delete
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default DeleteSeller;
