import axios from "axios";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { URL_USER } from "../../config/apiBaseURL";

const initialState = [];

export const getAllAdmin = createAsyncThunk("GET_ADMINS", () => {
  return axios.get(`${URL_USER}/users/admin/all`).then((r) => r.data);
});

export const getAdminById = createAsyncThunk("GET_ADMIN", (adminId) => {
  return axios
    .get(`${{ URL_USER }}/users/user/${adminId}`)
    .then((r) => r.data);
});

export const deleteAdmin = createAsyncThunk("DELETE_ADMIN", async (userId) => {
  const response = await axios.delete(
    `${URL_USER}/users/admin/delete/${userId}`
  );
  return response.data;
});
export const updateAdmin = createAsyncThunk("UPDATE_ADMIN", async (data) => {
  const response = await axios.put(`${URL_USER}/users/admin/update`, data);
  return response.data;
});

export const createAdmin = createAsyncThunk("CREATE_ADMIN", async (data) => {
  const response = await axios.post(`${URL_USER}/users/admin/create`, data);
  return response.data;
});

export const setAdmin = createAction("SET_ADMIN");

const adminReducer = createReducer(initialState, {
  [getAllAdmin.fulfilled]: (state, action) => action.payload,
  [deleteAdmin.fulfilled]: (state, action) => {
    if (action.payload) {
      const filteredUsers = state.filter(
        (user) => user._id !== action.payload._id
      );
      return filteredUsers;
    } else {
      return state;
    }
  },
  [createAdmin.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [setAdmin]: (state, action) => action.payload,
  [getAdminById.fulfilled]: (state, action) => action.payload,
  [updateAdmin.fulfilled]: (state, action) => {
    const updatedAdmin = action.payload;
    const index = state.findIndex((admin) => admin._id === updatedAdmin._id);
    if (index !== -1) {
      state[index] = updatedAdmin;
    }
  },
});

export default adminReducer;
