import {
  Box,
  Container,
  Flex,
  IconButton,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  List,
  ListItem,
  Text,
  Input,
  Textarea,
  Select,
  Link,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { FaRegPlayCircle } from "react-icons/fa";
import { FaRegCirclePause } from "react-icons/fa6";
import { useSelector } from "react-redux";

const LiveCallsPopUp = () => {
  const [liveCalls, setLiveCalls] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector((state) => state.user);
  const customers = useSelector((state) => state.clients);
  const [filteredLiveCalls, setFilteredLiveCalls] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [subject, setSubject] = useState("");
  const [customerNotes, setCustomerNotes] = useState("");
  const [internalNotes, setInternalNotes] = useState("");
  const [status, setStatus] = useState("Open");
  const [matchedCustomer, setMatchedCustomer] = useState(null);

  const intervalIdRef = useRef(null);
  const prevFilteredLiveCallsRef = useRef([]);

  const fetchLiveCalls = () => {
    axios
      .get("https://api.nevtis.com/fusionpbx/calls/live-calls")
      .then((res) => {
        setLiveCalls(res.data);
      })
      .catch((err) => {
        console.error("Error fetching live calls:", err);
      });
  };

  useEffect(() => {
    if (isFetching && !isPaused) {
      fetchLiveCalls();
      intervalIdRef.current = setInterval(fetchLiveCalls, 4000);
    }

    // Limpieza del intervalo al desmontar el componente o cambiar `isFetching` o `isPaused`
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [isFetching, isPaused]);

  useEffect(() => {
    if (isOpen) {
      // Cuando el modal está abierto, pausamos el fetch
      setIsPaused(true);
      setIsFetching(false); // Detener el fetch
    }
  }, [isOpen]);

  useEffect(() => {
    const filteredCalls = liveCalls.filter((call) => {
      const domain = extractPBXURL(call);
      return domain === "nevtishq.nevtisvoice.com";
      /* return domain === "couvertla.nevtisvoice.com"; */
    });

    if (filteredCalls.length > 0) {
      const sortedCalls = filteredCalls.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );

      const mostRecentCall = sortedCalls[0];
      setFilteredLiveCalls([mostRecentCall]);

      const foundCustomer = findCompanyByPhone(mostRecentCall.cid_num);
      setMatchedCustomer(foundCustomer);
      setSelectedCustomer(foundCustomer);
      onOpen();
    }

    prevFilteredLiveCallsRef.current = filteredCalls;
  }, [liveCalls]);
  const handleToggleFetch = () => {
    if (isPaused) {
      setIsPaused(false);
      setIsFetching(true);
    } else {
      setIsPaused(true);
      clearInterval(intervalIdRef.current);
    }
  };

  const extractPBXURL = (call) => {
    if (call.b_presence_id) {
      const parts = call.b_presence_id.split("@");
      return parts.length > 1 ? parts[1] : "N/A";
    } else if (call.presence_id) {
      const parts = call.presence_id.split("@");
      return parts.length > 1 ? parts[1] : "N/A";
    } else if (call.accountcode) {
      return call.accountcode;
    } else {
      return "";
    }
  };

  const findCompanyByPhone = (phoneNumber) => {
    const formattedPhoneNumber = `+1${phoneNumber}`;
    for (let company of customers) {
      if (company.phone === formattedPhoneNumber) {
        return company;
      }

      for (let vTextEntry of company.vText) {
        if (vTextEntry.phone === formattedPhoneNumber) {
          return company;
        }
      }
    }
    return null;
  };

  const handleSelectChange = (e) => {
    const selectedCustomerId = e.target.value;
    const customer = customers.find((c) => c._id === selectedCustomerId);
    setSelectedCustomer(customer);
  };

  const customerOptions = customers.map((customer) => (
    <option key={customer._id} value={customer._id}>
      {customer.companyName || "Cliente No Encontrado"}
    </option>
  ));

  const handleSubmit = () => {
    axios
      .post("https://api.nevtis.com/interaction/tickets/create", {
        company: selectedCustomer._id,
        subject,
        customerNotes,
        internalNotes,
        status,
        user: user._id,
      })
      .then((resp) => {
        onClose();
      });
  };

  const handleClose = () => {
    onClose();
    setIsPaused(true); // Pausar el fetch de llamadas
    setIsFetching(false); // Detener el fetch
  };

  return (
    <Container>
      <Flex>
        <Flex textAlign="center">
          <IconButton
            bg={"transparent"}
            size={"xs"}
            onClick={handleToggleFetch}
            icon={
              isPaused ? (
                <Flex>
                  <FaRegPlayCircle color="green" size={"25px"} />
                  <Text mt={1} ml={2}>
                    Activate Call Listening
                  </Text>
                </Flex>
              ) : (
                <FaRegCirclePause color="red" size={"25px"} />
              )
            }
          />
        </Flex>
        {isFetching && !isPaused && (
          <Flex textAlign="center" ml={4}>
            <Text mr={2}>Call Listening Active</Text>
            <Spinner size="md" />
          </Flex>
        )}
      </Flex>

      {/* Popup Modal */}
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"} color={"nevtis.1"}>
            New Call Detected
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <List spacing={3}>
              {filteredLiveCalls.map((call, index) => (
                <ListItem key={index}>
                  <strong color="nevtis.1">Caller:</strong> {call.cid_name} (
                  {call.cid_num})
                  <br />
                  <strong>Destination:</strong> {call.dest}
                </ListItem>
              ))}
            </List>

            {matchedCustomer ? (
              <Box>
                <Text>
                  <strong>Company Name:</strong> {matchedCustomer.companyName}
                </Text>
                <Text>
                  <strong>Email:</strong> {matchedCustomer.email}
                </Text>
                <Text>
                  <strong>Server URL:</strong>{" "}
                  <Link
                    href={matchedCustomer.serverURL}
                    isExternal
                    color={"blue"}
                  >
                    {matchedCustomer.serverURL}
                  </Link>
                </Text>
                <Text>
                  <strong>Pbx URL:</strong>{" "}
                  <Link
                    color={"blue"}
                    href={`https://${matchedCustomer.domain.domain_name}`}
                    isExternal
                  >
                    {matchedCustomer.domain.domain_name}
                  </Link>
                </Text>
              </Box>
            ) : (
              <Box mt={4}>
                <Text mb={2}>Select Customer</Text>
                <Select
                  placeholder="Select customer"
                  onChange={handleSelectChange}
                >
                  {customerOptions}
                </Select>
              </Box>
            )}

            <Box mt={4}>
              <Text mb={2}>Subject</Text>
              <Input
                placeholder="Enter subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </Box>
            <Box mt={4}>
              <Text mb={2}>Customer Notes</Text>
              <Textarea
                placeholder="Enter customer notes"
                value={customerNotes}
                onChange={(e) => setCustomerNotes(e.target.value)}
              />
            </Box>
            <Box mt={4}>
              <Text mb={2}>Internal Notes</Text>
              <Textarea
                placeholder="Enter internal notes"
                value={internalNotes}
                onChange={(e) => setInternalNotes(e.target.value)}
              />
            </Box>
            <Box mt={4}>
              <Text mb={2}>Status</Text>
              <Select
                placeholder="Select status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="Open">Open</option>
                <option value="In Progress">In Progress</option>
                <option value="Closed">Closed</option>
                <option value="Pending">Pending</option>
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Submit Ticket
            </Button>
            <Button colorScheme="red" onClick={handleClose}>
              Close and Pause Live Call
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default LiveCallsPopUp;
