import React, { useState, useEffect } from "react";
import { fetchAccessToken } from "../../helpers/countryAPI/auth";
import { fetchStates, fetchCities } from "../../helpers/countryAPI/api";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllServiceCalls,
  createServiceCall,
} from "../../store/reducers/serviceCalls.reducer";
import {
  Box,
  Input,
  Checkbox,
  Stack,
  InputGroup,
  FormControl,
  FormLabel,
  Flex,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  Select,
} from "@chakra-ui/react";
import TableComponent from "./TableComponent";
import Tickets from "./Tickets";

const ServiceScheduler = () => {
  const dispatch = useDispatch();
  const servicesDB = useSelector((state) => state.serviceCalls);

  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [port, setPort] = useState("");
  const [install, setInstall] = useState("");
  const [docs, setDocs] = useState(false);
  const [billing, setBilling] = useState(false);
  const [notes, setNotes] = useState("");
  const [states, setStates] = useState([]);
  const [showCompleted, setShowCompleted] = useState(false);
  const [cities, setCities] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [sortedServices, setSortedServices] = useState([]);

  useEffect(() => {
    fetchAccessToken()
      .then((token) => {
        setAuthToken(token);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    const country = "United States";
    if (authToken) {
      fetchStates(authToken, country)
        .then((data) => {
          setStates(data);
        })
        .catch((error) => {});
    }
  }, [authToken]);

  const handleStateSelection = ({ target }) => {
    const state = target.value;
    setState(state);
    if (authToken) {
      fetchCities(authToken, state)
        .then((data) => {
          setCities(data);
        })
        .catch((error) => {});
    }
  };

  const handleCitySelection = (e) => {
    setCity(e.target.value);
  };

  useEffect(() => {
    dispatch(getAllServiceCalls());
  }, [dispatch]);

  const handleSubmit = () => {
    const newService = {
      name,
      state,
      city,
      address,
      port,
      install,
      docs,
      billing,
      notes,
      made: false,
    };
    dispatch(createServiceCall(newService));
    setName("");
    setState("");
    setCity("");
    setAddress("");
    setPort("");
    setInstall("");
    setDocs(false);
    setBilling(false);
    setNotes("");
  };

  useEffect(() => {
    const sorted = [...servicesDB].sort((a, b) => {
      return new Date(b.install) - new Date(a.install);
    });
    setSortedServices(sorted);
  }, [servicesDB]);

  return (
    <Box px={6} width={"100%"} mt={10}>
      <Accordion
        allowToggle
        mt={4}
        border="1px"
        borderColor="gray.200"
        boxShadow="xl"
        rounded="md"
        bg="white"
      >
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Create Service:
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <FormControl
              mt={4}
              border="1px"
              borderColor="gray.200"
              p={4}
              rounded="md"
              shadow="md"
            >
              <FormLabel>Services:</FormLabel>
              <Stack spacing={4}>
                <Flex>
                  <InputGroup flex={1} mr={4}>
                    <Input
                      placeholder="Service name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      size="sm"
                    />
                  </InputGroup>
                  <InputGroup flex={1}>
                    <Select
                      placeholder="Select an state"
                      size="sm"
                      onChange={handleStateSelection}
                    >
                      {states.map((state) => (
                        <option key={state.state_name} value={state.state_name}>
                          {state.state_name}
                        </option>
                      ))}
                    </Select>
                  </InputGroup>
                  <InputGroup flex={1}>
                    <Select
                      placeholder="Select an a city"
                      size="sm"
                      onChange={handleCitySelection}
                    >
                      {cities.map((city) => (
                        <option key={city.city_name} value={city.city_name}>
                          {city.city_name}
                        </option>
                      ))}
                    </Select>
                  </InputGroup>
                  <InputGroup flex={1}>
                    <Input
                      placeholder="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      size="sm"
                    />
                  </InputGroup>
                </Flex>
                <Flex>
                  <InputGroup flex={1} mr={4}>
                    <Input
                      placeholder="Port number"
                      value={port}
                      onChange={(e) => setPort(e.target.value)}
                      size="sm"
                    />
                  </InputGroup>
                  <InputGroup flex={1} mr={4}>
                    <Input
                      placeholder="Select Date and Time"
                      type="datetime-local"
                      value={install}
                      onChange={(e) => setInstall(e.target.value)}
                      size="sm"
                    />
                  </InputGroup>
                  <Stack direction="row" spacing={4} flex={1}>
                    <Checkbox
                      isChecked={docs}
                      onChange={(e) => setDocs(e.target.checked)}
                    >
                      Docs
                    </Checkbox>
                    <Checkbox
                      isChecked={billing}
                      onChange={(e) => setBilling(e.target.checked)}
                    >
                      Billing
                    </Checkbox>
                  </Stack>
                </Flex>
                <InputGroup>
                  <Input
                    placeholder="Enter notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    size="sm"
                  />
                </InputGroup>
                <Button colorScheme="orange" mt={4} onClick={handleSubmit}>
                  Schedule
                </Button>
              </Stack>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Tabs
        mt={4}
        onChange={() => setShowCompleted(!showCompleted)}
        variant="enclosed"
      >
        <TabList>
          <Tab>Scheduled</Tab>
          <Tab>Completed</Tab>
          <Tab>Tickets</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box height="60vh" overflowY="auto">
              <TableComponent
                data={sortedServices.filter((service) => !service.made)}
                isCompleted={false}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box height="60vh" overflowY="auto">
              <TableComponent
                data={sortedServices.filter((service) => service.made)}
                isCompleted={true}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box height="60vh" overflowY="auto">
              <Tickets
                data={sortedServices.filter((service) => service.made)}
                isCompleted={true}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ServiceScheduler;
