import React from "react";
import Loading from "../../../commons/general/Loading";
import { Box, Grid, Text, Flex, useMediaQuery } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import SellerDashboard from "./SellerDashboard";

const SellerDetail = ({ seller }) => {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  if (!seller) {
    return <Loading />;
  }
  return (
    <Box>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
        gap={4}
        mb="4"
        mt={10}
      >
        <Text fontWeight="bold" color="gray.600">
          Contact: {seller.name}
        </Text>
        <Text fontWeight="bold" color="gray.600">
          Email: {seller.email}
        </Text>
        <Text fontWeight="bold" color="gray.600">
          Phone: {seller.phone}
        </Text>
        <Text fontWeight="bold" color="gray.600">
          Address: {seller.address}
        </Text>
        <Text fontWeight="bold" color="gray.600">
          Commission: {seller.commissionRate}%
        </Text>
        {seller.referredBy && (
          <Flex alignItems="center">
            <Text fontWeight="bold" color="gray.600">
              Referred By:{" "}
              <Link
                to={`/seller/${seller.referredBy._id}`}
                color="nevtis.1"
                as="u"
                ml={2}
              >
                {seller.referredBy.name}
              </Link>
            </Text>
          </Flex>
        )}
      </Grid>
      {isDesktop && <SellerDashboard />}
    </Box>
  );
};

export default SellerDetail;
