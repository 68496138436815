import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  useDisclosure,
  useToast,
  Stack,
  FormControl,
  FormLabel,
  Input,
  MenuItem,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateSeller } from "../../../store/reducers/seller.reducer";

const EditSeller = ({ user }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [employeeData, setEmployeeData] = useState([user]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onChange = (e, _id) => {
    const { name, value, type, checked } = e.target;
    const updatedEmployeeData = employeeData.map((item) =>
      item._id === _id
        ? { ...item, [name]: type === "checkbox" ? checked : value }
        : item
    );
    setEmployeeData(updatedEmployeeData);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const _id = user._id;
    const resp = await dispatch(updateSeller({ ...data, _id }));
    if (resp.payload) {
      setLoading(false);
      onClose();
      toast({
        title: `User Updated`,
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "bottom-left",
      });
    } else {
      setLoading(false);
      onClose();
      toast({
        title: `Error`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  return (
    <>
      <MenuItem onClick={onOpen}>Edit {user.companyName}</MenuItem>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">Edit</DrawerHeader>
          <DrawerBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <>
                {employeeData.map(
                  ({ _id, name, address, phone, commissionRate }) => (
                    <Stack spacing={2} key={_id}>
                      <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                          {...register("name")}
                          value={name}
                          onChange={(e) => onChange(e, _id)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Address</FormLabel>
                        <Input
                          {...register("address")}
                          value={address}
                          onChange={(e) => onChange(e, _id)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Phone Number</FormLabel>
                        <Input
                          {...register("phone")}
                          value={phone}
                          onChange={(e) => onChange(e, _id)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Comission</FormLabel>
                        <Input
                          {...register("commissionRate")}
                          value={commissionRate}
                          type={"number"}
                          onChange={(e) => onChange(e, _id)}
                        />
                      </FormControl>

                      <Stack spacing={10}>
                        <Stack
                          direction={{ base: "column", sm: "row" }}
                          align={"start"}
                          justify={"space-between"}
                        ></Stack>
                        <Button
                          isLoading={loading}
                          type="submit"
                          bg={"rgba(255,134,0)"}
                          color={"black"}
                          _hover={{
                            bg: "rgba(255,134,0,0.5)",
                          }}
                        >
                          Edit
                        </Button>
                      </Stack>
                    </Stack>
                  )
                )}
              </>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default EditSeller;
