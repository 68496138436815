import React from "react";
import Loading from "../../../commons/general/Loading";
import { Box, Grid, Text } from "@chakra-ui/react";

const AdminDetail = ({ admin }) => {
  if (!admin) {
    return <Loading />;
  }

  return (
    <Box>
      <Grid
        templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
        gap={4}
        textAlign="center"
        mb={4}
        mt={10}
      >
        <Text fontWeight="bold">Contact: {admin.name}</Text>
        <Text fontWeight="bold">Email: {admin.email}</Text>
        <Text fontWeight="bold">Phone: {admin.phone}</Text>
        <Text fontWeight="bold">Address: {admin.address}</Text>
        <Text fontWeight="bold">
          Role: {admin.superAdmin ? "Super Admin" : "Admin"}
        </Text>
      </Grid>
    </Box>
  );
};

export default AdminDetail;
