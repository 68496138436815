import React from "react";
import { Flex, FormControl, FormLabel, Button } from "@chakra-ui/react";
import { buscarDireccionEnGoogleMaps } from "../../helpers/googleMaps/googleMapsQuery";
import { SiGooglestreetview } from "react-icons/si";

const FormularioDireccion = ({ city, state, address }) => {
  const maps = `${address}, ${city}, ${state}, EE. UU.`;

  const handleSubmit = (e) => {
    e.preventDefault();
    buscarDireccionEnGoogleMaps(maps);
  };

  return (
    <Flex justify="end">
      <form onSubmit={handleSubmit} align="right">
        <FormControl mt={2}>
          <Flex>
            <FormLabel>See Address on :</FormLabel>
            {/*  <p pr={2}>{address} </p> */}
            {/*  <Input type="text" value={address} onChange={handleChange} size="xs" />
             */}
            <Button
              type="submit"
              size="md"
              colorScheme="orange"
              variant="outline"
              py={2}
            >
              <SiGooglestreetview size="sm" />
            </Button>
          </Flex>
        </FormControl>
      </form>
    </Flex>
  );
};

export default FormularioDireccion;
