/* import logger from "redux-logger"; */
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/user.reducer";
import adminReducer from "./reducers/admin.reducer";
import clientReducer from "./reducers/client.reducer";
import sellerReducer from "./reducers/seller.reducer";
import softphoneUser from "./reducers/softphoneUser.reducer";
import extensionFusionPbx from "./reducers/extensionFusionPbx.reducer";
import domainFusionPbx from "./reducers/domainFusionPbx.reducer";
import { chatReducer, messageReducer } from "./reducers/chat.reducer"; // Importa los reducers individualmente
import serviceCalls from "./reducers/serviceCalls.reducer";
/* 
import messagesReducer from "./reducers/messages.reducer";
import messageReducer from "./reducers/messages.reducer";
import service from "./reducers/service.reducer";
import pbxExtensions from "./reducers/pbxExtensions.reducer";
import proposars from "./reducers/proposar.reducer";
import vText from "./reducers/vtext.reducer";
*/
export const store = configureStore({
  /* middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger), */
  reducer: {
    user: userReducer,
    admins: adminReducer,
    softphoneUsers: softphoneUser,
    clients: clientReducer,
    sellers: sellerReducer,
    chats: chatReducer,
    messages: messageReducer, // Agrega el reducer de mensajes al almacenamiento
    extensions: extensionFusionPbx,
    domains: domainFusionPbx,
    serviceCalls: serviceCalls,
    /* messages: messagesReducer, */
    /* messages: messageReducer, */
    /* services: service,
    pbxExtensions: pbxExtensions,
    proposars: proposars,
    serviceCalls: serviceCalls,
    vText: vText, */
  },
});
