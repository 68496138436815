import React from "react";
import { Flex } from "@chakra-ui/react";
import ExtensionGraph from "./ExtensionGraph";
import CallsGraph from "./CallsGraph";
import DestinationsGraph from "./DestinationsGraph";
import RingGroupsGraph from "./RingGroupsGraph";

const CustomerDashboard = () => {
  return (
    <Flex mt={20}>
      <ExtensionGraph />
      <CallsGraph />
      <DestinationsGraph />
      <RingGroupsGraph />
    </Flex>
  );
};

export default CustomerDashboard;
