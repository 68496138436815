import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeAdmin from "../components/admin/HomeAdmin";
import CustomerTabs from "../components/admin/Gest Customer/CustomerTabs";
import CustomerDetailsTabs from "../components/admin/Gest Customer/CustomerDetailsTabs";
import SoftphoneDetailsTabs from "../components/admin/Gest Softphone/SoftphoneDetailsTabs";
import TableAdmin from "../components/admin/Gest Admin/TableAdmin";
import AdminDetailsTabs from "../components/admin/Gest Admin/AdminDetailsTabs";
import SellerDetailsTabs from "../components/admin/Gest Seller/SellerDetailsTabs";
import TableUsers from "../components/admin/Gest Seller/TableSeller";
import ServiceScheduler from "../components/serviceCalls/SeviceScheduler";
import HomeMarketplace from "../components/Marketplace/HomeMarketplace";
const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeAdmin />} />
      <Route path="/client" element={<CustomerTabs />} />
      <Route path="/client/:customerId" element={<CustomerDetailsTabs />} />
      <Route
        path="/softphone-user/:softphoneId"
        element={<SoftphoneDetailsTabs />}
      />
      <Route path="/admin" element={<TableAdmin />} />
      <Route path="/admin/:adminId" element={<AdminDetailsTabs />} />
      <Route path="/seller/" element={<TableUsers />} />
      <Route path="/seller/:sellerId" element={<SellerDetailsTabs />} />
      <Route path="/schedule" element={<ServiceScheduler />} />
      <Route path="/marketplace" element={<HomeMarketplace />} />
    </Routes>
  );
};

export default AdminRoutes;
