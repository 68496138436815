// tableStyles.js
const headStyles = {
  border: '1px',
  borderColor: 'gray.300',
};

const cellStyles = {
  ...headStyles,
  p: 0,
  pl: 3,
};

const timeStyles = {
  fontSize: '11px',
  backgroundColor: 'gray',
  color: 'white',
  marginLeft: '0.5rem',
  borderRadius: '2px',
  paddingRight: '2px',
};

export { headStyles, cellStyles, timeStyles };
