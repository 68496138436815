// useDateAndFullscreen.js
import { useMediaQuery } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";

const useDateAndFullscreen = () => {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const [fechaActual, setFechaActual] = useState(new Date().toLocaleString());
  const [openStartCalendar, setOpenStartCalendar] = useState(false);
  const [openEndCalendar, setOpenEndCalendar] = useState(false);
  const [startSelectedDate, setStartSelectedDay] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endSelectedDate, setEndSelectedDay] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [fullscreen, setFullscreen] = useState(
    document.fullscreenElement !== null
  );

  const formattedNextDay = useMemo(() => {
    const startDate = new Date(endSelectedDate);
    const nextDay = new Date(startDate);
    nextDay.setDate(startDate.getDate() + 2);
    return `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${nextDay.getDate().toString().padStart(2, "0")}`;
  }, [endSelectedDate]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setFullscreen(document.fullscreenElement !== null);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [fullscreen]);

  useEffect(() => {
    const intervalo = setInterval(actualizarFechaHora, 60000);
    return () => clearInterval(intervalo);
  }, []);

  const actualizarFechaHora = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");

    const nuevaFechaHora = `${year}-${month}-${day} ${hours}:${minutes}`;
    setFechaActual(nuevaFechaHora);
  };

  const handleStartDateSelect = (date) => {
    const fechaFormateada = date.toISOString().split("T")[0];
    setStartSelectedDay(fechaFormateada);
    setOpenStartCalendar(false);
  };

  const handleEndDateSelect = (date) => {
    const fechaFormateada = date.toISOString().split("T")[0];
    setEndSelectedDay(fechaFormateada);
    setOpenEndCalendar(false);
  };

  const toggleFullScreen = () => {
    const elem = document.documentElement;

    if (!document.fullscreenElement) {
      setFullscreen(true);
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    } else {
      setFullscreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  return {
    isDesktop,
    fechaActual,
    openStartCalendar,
    setOpenStartCalendar,
    openEndCalendar,
    setOpenEndCalendar,
    startSelectedDate,
    setStartSelectedDay,
    endSelectedDate,
    setEndSelectedDay,
    fullscreen,
    toggleFullScreen,
    formattedNextDay,
    actualizarFechaHora,
    handleStartDateSelect,
    handleEndDateSelect,
  };
};

export default useDateAndFullscreen;
