import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../components/auth/Login";
import ForgotPassword from "../components/auth/ForgotPassword";
import CreatePassword from "../components/auth/CreatePassword";
import HomeScreenApp from "../components/AppAcrobits/HomeScreenApp";
import FormAccess from "../components/AppAcrobits/FormAccess";
import ForgotPassApp from "../components/AppAcrobits/ForgotPassApp";
import Home from "../components/callsDetails/Home";
import StatusDigitalOcean from "../components/StatusDigitalOcean";

const LoginRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/:token/:userEmail" element={<Login />} />
      <Route path="set-password/:action/:token" element={<CreatePassword />} />
      <Route path="/createPassword" element={<ForgotPassword />} />
      {/* ACROBITS */}
      <Route path="/app" element={<HomeScreenApp />} />
      <Route path="/app/access" element={<FormAccess />} />
      <Route path="/app/forgotpass" element={<ForgotPassApp />} />
      {/* ACROBITS */}
      <Route path="/calls-details" element={<Home />} />
      <Route path="/status" element={<StatusDigitalOcean />} />
    </Routes>
  );
};

export default LoginRoutes;
