import React from "react";
import { Box } from "@chakra-ui/react";


const AlertMessage = () => {
  const error=null
  return (
    <>
      {error ? (
        <Box py={1} color="red">
          {error ? <>{error}</> : <></>}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default AlertMessage;
