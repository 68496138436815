import axios from "axios";
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { URL_INTERACTIONS } from "../../config/apiBaseURL";

const initialState = [];

export const createServiceCall = createAsyncThunk(
  "CREATE_SERVICECALLS",
  async (serviceCallData) => {
    const response = await axios.post(
      `${URL_INTERACTIONS}/service-calls/create`,
      serviceCallData
    );
    return response.data;
  }
);

export const getAllServiceCalls = createAsyncThunk(
  "GET_ALL_SERVICECALLS",
  async () => {
    const response = await axios.get(`${URL_INTERACTIONS}/service-calls/all`);
    return response.data;
  }
);

export const getServiceCallById = createAsyncThunk(
  "GET_SERVICECALLS_BY_ID",
  async (id) => {
    const response = await axios.get(`${URL_INTERACTIONS}/service-calls/${id}`);
    return response.data;
  }
);

export const updateServiceCall = createAsyncThunk(
  "UPDATE_SERVICECALLS",
  async ({ id, serviceCallData }) => {
    const response = await axios.put(
      `${URL_INTERACTIONS}/service-calls/${id}`,
      serviceCallData
    );
    return response.data;
  }
);

export const deleteServiceCall = createAsyncThunk(
  "DELETE_SERVICECALLS",
  async (id) => {
    await axios.delete(`${URL_INTERACTIONS}/service-calls/${id}`);
    return id;
  }
);

const serviceCallsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createServiceCall.fulfilled, (state, action) => {
      state.push(action.payload);
    })
    .addCase(getAllServiceCalls.fulfilled, (state, action) => {
      return action.payload;
    })
    .addCase(getServiceCallById.fulfilled, (state, action) => {
      return action.payload;
    })
    .addCase(updateServiceCall.fulfilled, (state, action) => {
      const updatedServiceCall = action.payload;
      const index = state.findIndex((sc) => sc._id === updatedServiceCall._id);
      if (index !== -1) {
        state[index] = updatedServiceCall;
      }
    })
    .addCase(deleteServiceCall.fulfilled, (state, action) => {
      const id = action.payload;
      return state.filter((sc) => sc._id !== id);
    });
});

export default serviceCallsReducer;
