import React, { useState } from "react";
import {
  Stack,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Input,
  FormControl,
  Button,
  Box,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import axios from "axios";
import { showToast } from "../../../commons/SweetAlertToast";

const CreatePromotions = ({ setPromotions }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    discount: 0,
    validStart: "",
    validEnd: "",
  });
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const submit = async () => {
    // Validación de campos
    for (const key in formData) {
      if (formData[key] === "" && key !== "description") {
        setError(`Please complete all fields`);
        return;
      }
    }

    try {
      const response = await axios.post(
        "https://api.nevtis.com/marketplace/promotions/create",
        formData
      );

      setPromotions((prevPromotions) => [...prevPromotions, response.data]);
      showToast("Promotion created", "success");
      setFormData({
        name: "",
        discount: 0,
        validStart: "",
        validEnd: "",
      });
      onClose();
    } catch (error) {
      console.error("Error creating Promotion:", error);
      setError("Failed to create Promotion");
    }
  };

  return (
    <>
      <Center mt={4}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          bg="rgb(255,134,0)"
          _hover={{ bg: "rgba(255,134,0,0.5)" }}
          onClick={onOpen}
          size="xs"
        >
          Add Promotion
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            textAlign={"center"}
            color={"nevtis.1"}
            fontSize={"2xl"}
            fontWeight={"bold"}
          >
            Create a new Promotion
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ width: "100%" }}>
              <Stack spacing={2} width={"full"}>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Promotion Name</FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Discount (%)</FormLabel>
                  <Input
                    type="number"
                    name="discount"
                    value={formData.discount}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Valid Start</FormLabel>
                  <Input
                    type="date"
                    name="validStart"
                    value={formData.validStart}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Valid End</FormLabel>
                  <Input
                    type="date"
                    name="validEnd"
                    value={formData.validEnd}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Stack>
            </Box>

            <Text color="red">{error}</Text>
            <Center mt={10} mb={4}>
              <Button
                onClick={submit}
                type="submit"
                bg={"nevtis.1"}
                _hover={{ bg: "nevtis.1.4" }}
                color={"white"}
              >
                Submit
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const formLabelStyle = {
  color: "rgba(255,134,0)",
  fontWeight: "bold",
};

export default CreatePromotions;
