import React from "react";
import {
  Box,
  FormControl,
  Input,
  InputGroup,
  Button,
  Text,
  Textarea,
  Select,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { BsEnvelope } from "react-icons/bs";
import Swal from "sweetalert2";
/* import axios from "axios";
import { URL_COMUNICATION } from "../../config/apiBaseURL"; */

const SmsChannel = ({ user }) => {
  const { register, handleSubmit } = useForm();

  const onSubmitEmail = async (data) => {
    Swal.fire({
      position: "center",
      icon: "warning",
      title: `In Progress`,
      showConfirmButton: false,
      timer: 1000,
    });
/*     const resp = await axios.post(
      `${URL_COMUNICATION}/api/email/send-custom-message`,
      {
        ...data,
        to: user.email,
        name: user.name,
      }
    );
    if (resp.data) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: `${resp.data}`,
        showConfirmButton: false,
        timer: 1500,
      });
      reset();
    } */
  };

  return (
    <Box mt={5}>
      <Text mb={5} color={"nevtis.1"} fontWeight={"bold"} fontSize={"2xl"}>
        Send an SMS to: {user.phone}
      </Text>
      <form onSubmit={handleSubmit(onSubmitEmail)}>
        <FormControl
          border="solid 1px lightgray"
          p={2}
          mt={1}
          borderRadius="md"
        >
          <Select id="emailFrom" {...register("from", { required: true })}>
            <option value="support@nevtis.com"> 7146849638 (Sales)</option>
            <option value="contact@nevtis.com">7146849643 (Support)</option>
          </Select>
          <InputGroup mb={2} my={5}>
            <Input
              type="text"
              id="subject"
              placeholder="Subject"
              isRequired
              {...register("subject")}
            />
          </InputGroup>
          <InputGroup mb={2}>
            <Textarea
              id="body"
              placeholder="Write your message here"
              isRequired
              {...register("message")}
            />
          </InputGroup>
          <Button type="submit" colorScheme="orange" ml={1} my={5}>
            <BsEnvelope style={{ marginRight: "5px" }} />
            Send SMS
          </Button>
        </FormControl>
      </form>
    </Box>
  );
};

export default SmsChannel;
